import { useState } from 'react';
import { Form } from 'antd';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { createClientTask, updateClientTask } from '../../../services/client';
import { getLoggedInUserId } from '../../../services/auth';
import Messages from "../../antDesign/Message";
import {statuses} from "../../../pages/Tasks/statuses";

export const useTaskForm = (isNew: boolean, task?: any, onSuccess?: () => void) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [editorState, setEditorState] = useState(
    isNew ? EditorState.createEmpty() :
      EditorState.createWithContent(convertFromRaw(JSON.parse(task?.body || '{}')))
  );
  const [dynamicFields, setDynamicFields] = useState<TASKS.TaskField[]>([]);
  const [property, setProperty] = useState<string | undefined>(undefined);

  const startLoading = (msg: string) => {
    setLoadingMsg(msg);
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
    setLoadingMsg('');
  };

  const createRequestBody = (values: TASKS.TaskFormData) => {
    const fields: any[] = [];
    const files: any[] = [];

    dynamicFields.forEach((field) => {
      if (!values.dynamic_fields?.[field.name] && field.type !== 'checkbox') return;
      if (field.type === 'file') {
        const fileData = values.dynamic_fields[field.name][0];
        fields.push({
          name: field.name,
          fileName: fileData.originFileObj.name,
          contentType: fileData.originFileObj.type,
          fileContent: fileData.originFileObj
        });
        files.push(fileData.originFileObj);
      } else {
        fields.push({
          name: field.name,
          value: values.dynamic_fields[field.name]
        });
      }
    });

    const status =  statuses.filter((status) => status.value === Number(values.status))[0];

    return {
      body: {
        ...values,
        status: JSON.stringify(status),
        body: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        dynamic_fields: fields,
        property: property,
        agentId: getLoggedInUserId()
      },
      files
    };
  };

  const handleSubmit = async (values: TASKS.TaskFormData) => {
    try {
      startLoading(isNew ? 'יוצר משימה...' : 'מעדכן משימה...');
      const request = createRequestBody(values);

      const response = isNew ?
        await createClientTask(request.body, request.files) :
        await updateClientTask({ ...request.body, id: task?._id}, request.files);
      if (response.status === 'SUCCESS') {
        Messages.Success(isNew ? 'המשימה נוצרה בהצלחה' : 'המשימה עודכנה בהצלחה')
        if(onSuccess) {
          onSuccess();
        }
      } else {
        Messages.Error(isNew ? 'יצירת המשימה נכשל' : 'עדכון המשימה נכשל');
      }
    } catch (error) {
      Messages.Error(isNew ? 'יצירת המשימה נכשל' : 'עדכון המשימה נכשל');
    } finally {
      stopLoading();
    }
  };

  return {
    form,
    loading,
    loadingMsg,
    editorState,
    setEditorState,
    dynamicFields,
    setDynamicFields,
    handleSubmit,
    setProperty
  };
};
