import React, {useEffect, useState} from 'react';
import {authorized, authorizedType, getLoggedInUser, getLoggedInUserId, getUserId} from "../../../services/auth";
import moment from "moment";
import {createClientSMS, getClientDetails, getClientDetailsProducts, orderMislakaSMS} from "../../../services/client";
import Loader from "../../antDesign/Loader";
import TopMenu from "../../VerticalLayout/Client/TopMenu";
import {Card, Col, Modal, Row, Typography} from "antd";
import Button from '../../antDesign/Button';
import Tabs from "../../antDesign/Tabs";
import ProductsTab from "./tabs/Products";
import DocumentsTab from "./tabs/Documents";
import ProductsElementaryTab from "./tabs/ProductsElementary";
import TasksTab from "./tabs/Tasks";
import {CodeSandboxOutlined, DeliveredProcedureOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';
import ViewClientModal from "../ViewClientModal";
import AddEditClientModal from "../AddEditClientModal";
import DocumentsSignatureModal from "../DocumentsSignatureModal";
import ConnectMountainInsurance from "../components/modals/connectMountainInsurance";
import Boxes from "../components/modals/boxes";
import Messages from "../../antDesign/Message";
import TaskFormModal from "../../Tasks/components/TaskForm/TaskFormModal";

const {Text} = Typography;

const ClientDetails: React.FC = () => {
  const [clientId, setClientId] = useState<string>('');
  const [client, setClient] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderMislakaLoading, setOrderMislakaLoading] = useState<boolean>(false);
  const [createClientSMSLoading, setCreateClientSMSLoading] = useState<boolean>(false);
  const [isSMSExistingClient, setIsSMSExistingClient] = useState<boolean>(false);
  const [userViewModalShow, setUserViewModalShow] = useState<boolean>(false);
  const [addEditClientModalShow, setAddEditClientModalShow] = useState<boolean>(false);
  const [addEditTaskClientModalShow, setAddEditTaskClientModalShow] = useState<boolean>(false);
  const [products, setProducts] = useState<any>([]);
  const [isNew, setNew] = useState<boolean>(false);
  const [activeTabJustify, setActiveTabJustify] = useState<string>('1');
  const [reloadTasks, setReloadTasks] = useState<boolean>(false);
  const [visibleSendDocumentsModal, setVisibleSendDocumentsModal] = useState<boolean>(false);
  const [connectMountainInsuranceModalShow, setConnectMountainInsuranceModalShow] = useState<boolean>(false);
  const [hasClient, setHasClient] = useState<boolean>(false);
  const [boxesModalShow, setBoxesModalShow] = useState<boolean>(false);
  const [tabItems, setTabItems] = useState<any>([]);
  const [isForceOrderModal, setIsForceOrderModal] = useState<boolean>(false);
  const [forceOrderData, setForceOrderData] = useState<any>({});

  const backTo = (route: string) => {
    switch (route) {
      case 'clientList':
        window.location.href = "/clients-list";
        break;
      case 'loginClient':
        window.location.href = "/login/client";
        break;
      case 'treatmentClientList':
        window.location.href = "treatment/clients-list";
        break;
    }
  }

  const backAnotherPage = () => {
    const user = getLoggedInUser();
    if (user.type === authorizedType.CLIENT)
      backTo('loginClient')
    else if (user.type === authorizedType.AGENT)
      backTo('clientList')
    else if (user.type === authorizedType.TREATMENT)
      backTo('treatmentClientList')
  }

  const getClientData = async () => {
    return await getClientDetails({clientId, type: getLoggedInUser().type}).then((data) => {
      return data
    });
  }

  const getClientProducts = async () => {
    return await getClientDetailsProducts({clientId})
      .then((data) => {
        return data.client.products;
      });
  }

  const closeViewUserModal = () => {
    setUserViewModalShow(false);
    setNew(false);
  }

  const loadClientAndProducts = (data: any) => {
    if (!data || !data.client) {
      backAnotherPage()
      return;
    }

    data.client.dateOfBirth = !data.client.dateOfBirth ? undefined : moment(data.client.dateOfBirth)
    data.client.issueIdDate = !data.client.issueIdDate ? undefined : moment(data.client.issueIdDate)
    setClient(data.client);
    setLoading(false);

    getClientProducts().then((products: any) => {
      setProducts(products)
    })
  }

  const closeAddEditClientModal = (refresh: boolean) => {
    if (refresh) {
      setLoading(true);
      getClientData().then((data) => {
        setLoading(false);
        loadClientAndProducts(data);
      });
    }
    setAddEditClientModalShow(false);
    setNew(false);
  }

  const closeAddEditTaskModal = (refresh: boolean) => {
    if (refresh) {
      setLoading(true);
      getClientData().then((data) => {
        // if tasks tab
        if (activeTabJustify === "3") {
          setReloadTasks(true);
        }
        setLoading(false);
        loadClientAndProducts(data);
      });
    }
    setAddEditTaskClientModalShow(false);
  }

  const handleCreateClintSMS = (clientId: string, forceUpdate: boolean | undefined) => {
    setCreateClientSMSLoading(true);
    createClientSMS({clientId: clientId, forceUpdate: forceUpdate}).then((response) => {
      if (response.status === 'ERROR') {
        switch (response.code) {
          case 401:
            Messages.Error({
              content: (
                <>
                  התחברות למערכת SMS נכשלה, נא בדוק את פרטי התחברות
                  <a href="/profile" className="text-blue-500 hover:underline"> באיזור האישי </a>
                </>
              )
            }).then(() => {
            });
            setCreateClientSMSLoading(false);
            break;
          case 409:
            setIsSMSExistingClient(true);
            break
          default:
            Messages.Error('יצירת לקוח ב SMS נכשלה').then(() => {
            });
            setCreateClientSMSLoading(false);
        }

      }

      if (response.status === 'SUCCESS') {
        Messages.Success('עמית הוקם/עודכן במערכת SMS בהצלחה!').then(() => {
        });
        setCreateClientSMSLoading(false);
      }
      setOrderMislakaLoading(false);
    });
  }

  const handleOrderMislaka = (data: any) => {
    setOrderMislakaLoading(true);
    orderMislakaSMS(data).then((response) => {
      if (response.status === 'MISSING-ERROR') {
        Modal.error({
          title: 'חוסר מידע',
          content: (
            <div>
              <p>על מנת להזמין מסלקה יש להשלים את הפרטים הבאים:</p>
              <ul>
                {response.list.map((data: String) => <li>{data}</li>)}
              </ul>
            </div>)
        });
      } else if (response.status === 'ERROR') {
        switch (response.code) {
          case 401:
            Messages.Error({
              content: (
                <>
                  התחברות למערכת SMS נכשלה, נא בדוק את פרטי התחברות
                  <a href="/profile" className="text-blue-500 hover:underline"> באיזור האישי </a>
                </>
              )
            }).then(() => {
            });
            break;
          case 404:
            Messages.Error('עמית זה אינו קיים במערכת SMS, יש ליצור עמית זה תחילה').then(() => {
            });
            break;
          case 409:
            setForceOrderData(data);
            setIsForceOrderModal(true);
            break;
          default:
            Messages.Error('הזמנת קובץ מסלקה נכשל').then(() => {
            });
        }
      }

      if (response.status === 'SUCCESS') {
        Messages.Success('קובץ מסלקה הוזמן בהצלחה!').then(() => {
        })
      }
      setOrderMislakaLoading(false);
    });
  }

  const onInit = () => {
    const params = new URLSearchParams(window.location.search);
    const url = window.location.href.split('?');
    const user = getLoggedInUser();
    // agent or treatment
    const cid = params.get('cid');
    if (url.length > 1 && url[0].endsWith('client-details')) {
      if (cid) {
        setClientId(cid);
        setHasClient(false);
      }

      if (!user.type)
        backTo('clientList');

    } else
      // client
    if (window.location.href.endsWith('client/details')) {
      setClientId(getLoggedInUserId());
      setHasClient(true);
      if (!user.type) {
        backTo('loginClient');
      }
    }
  }

  useEffect(() => {
    onInit();
    return () => {
    }
  }, []);

  useEffect(() => {
    if (clientId && authorized(['agent', 'treatment', 'client'])) {
      getClientData().then((data: any) => {
        loadClientAndProducts(data);
      }).catch(() => {
        setLoading(false);
      });
    }

    return () => {
    }
  }, [clientId]);

  useEffect(() => {
    if (client && clientId) {
      const items = [
        {label: 'מוצרים פנסיונים', key: '1', children: <ProductsTab isClient={hasClient} clientId={clientId}/>},
        {
          label: 'מוצרים אלמנטרים ופרט',
          key: '2',
          children: <ProductsElementaryTab lastDate={client?.insuranceMountainLastDate} isSigned={isSigned()}
                                           isClient={hasClient} clientId={clientId}/>
        },
      ]

      if (authorized(['agent', 'treatment'])) {
        items.push(
          {label: 'מסמכים', key: '3', children: <DocumentsTab/>},
          {
            label: 'משימות',
            key: '4',
            children: <TasksTab reload={reloadTasks} onReloaded={() => setReloadTasks(false)}/>
          })
      }
      setTabItems(items);
    }
    return () => {
    }
  }, [client, clientId]);

  const cardActions = () => {
    return (
      <Row>
        <Col xs={24}>
          <Card>
            <Button type="primary"
                    size="large"
                    onClick={() => {
                      setAddEditTaskClientModalShow(true);
                      // setNewTask(true);
                      // setTaskDataModal({});
                    }}>
              <i className="mdi mdi-plus mr-2"/>צור משימה
            </Button>

            <Button type="primary"
                    className="blue-btn ml-2"
                    size="large"
                    onClick={() => {
                      setVisibleSendDocumentsModal(true);
                    }}>
              <i className="mdi mdi-file-document-outline mr-2"/>חתימת מסמכים
            </Button>

            {/*   <Button type="primary"
                    style={{background: '#2c7ffc', borderColor: '#2c7ffc'}}
                    size="large"
                    className='ml-2'
                    onClick={() => {
                      setConnectMountainInsuranceModalShow(true);
                    }}>
              <i className="mdi mdi-file-document-outline mr-2"/>מידע ביטוחי הר הביטוח
            </Button>*/}

            <Button type="primary"
                    size="large"
                    className='orange-btn ml-2'
                    icon={<CodeSandboxOutlined/>}
                    onClick={() => {
                      setBoxesModalShow(true);
                    }}>צור קובץ קוביות (ה׳1)</Button>

            <Button type="primary"
                    loading={createClientSMSLoading}
                    size="large"
                    className='accent-coral-btn ml-2'
                    onClick={() => {
                      handleCreateClintSMS(client?._id, undefined)
                    }}>
              <i className="mdi mdi-file-document-outline mr-2"/>צור עמית ב SMS
            </Button>

            <Button type="primary"
                    loading={orderMislakaLoading}
                    size="large"
                    className='pink-btn ml-2'
                    icon={<DeliveredProcedureOutlined/>}
                    onClick={() => {
                      handleOrderMislaka({smsClientId: client?.smsClientId, clientId: client?._id, forceOrder: false})
                    }}
            >הזמן מסלקה</Button>
          </Card>
        </Col>
      </Row>
    )
  }

  const cardClientDetails = () => {
    return <Row>
      <Col xs={24}>
        <Card title={'פרטי עמית'}
              extra={authorized(['agent', 'treatment']) ? <>
                <Button
                  icon={<EditOutlined/>}
                  size={"middle"}
                  className='mx-2'
                  onClick={() => {
                    setAddEditClientModalShow(true);
                    setNew(false);
                  }}>
                </Button>

                <Button
                  icon={<EyeOutlined/>}
                  size={"middle"}
                  onClick={() => {
                    setUserViewModalShow(true);
                    setNew(true);
                  }}>
                </Button></> : null}
              cover={
                <Row className="p-4" style={{display: 'flex'}}>
                  <Col lg={6} md={8} sm={8} xs={8} className="pt-2">
                    <div><Text strong>תעודת זהות</Text></div>
                    <div><Text>{client?.clientId || '-'}</Text></div>
                  </Col>
                  <Col lg={6} md={8} sm={8} xs={8} className="pt-2">
                    <div><Text strong>שם פרטי</Text></div>
                    <div><Text>{client?.firstName || '-'}</Text></div>
                  </Col>
                  <Col lg={6} md={8} sm={8} xs={8} className="pt-2">
                    <div><Text strong>שם משפחה</Text></div>
                    <div><Text>{client?.lastName || '-'}</Text></div>
                  </Col>
                  <Col lg={6} md={8} sm={8} xs={8} className="pt-2">
                    <div><Text strong>אימייל</Text></div>
                    <div><Text>{client?.email || '-'}</Text></div>
                  </Col>
                  <Col lg={6} md={8} sm={8} xs={8} className="pt-2">
                    <div><Text strong>טלפון נייד</Text></div>
                    <div><Text>{client?.phone || '-'}</Text></div>
                  </Col>
                  <Col lg={6} md={8} sm={8} xs={8} className="pt-2">
                    <div><Text strong>טלפון קווי</Text></div>
                    <div><Text>{client?.telephone || '-'}</Text></div>
                  </Col>
                </Row>
              }
        />
      </Col>
    </Row>
  }

  const isSigned = () => {
    if (client) {
      const appendixDocuments = client?.appendixDocuments.filter((document: any) => document.appendixType === 'appendixB');
      return appendixDocuments.length > 0 && appendixDocuments[0].isSigned;
    }
    return false;
  }

  const cardTabs = () => {
    return <Row>
      <Col xs={24}>
        <Card>
          {
            authorized(['agent', 'treatment', 'client']) &&
            <Tabs
              items={tabItems}
              defaultActiveKey={activeTabJustify}
              onChange={(value) => {
                setActiveTabJustify(value)
              }}/>
          }
        </Card>
      </Col>
    </Row>
  }

  return (
    <Loader.LoadingContainer loading={loading} tip='טוען...'>
      <React.Fragment>
        {
          authorized(['client']) &&
          <TopMenu/>
        }

        <div className="page-content">
          {
            authorized(['agent', 'treatment']) &&
            <>{cardActions()}</>
          }

          {cardClientDetails()}
          {cardTabs()}

          <ViewClientModal visible={userViewModalShow}
                           clientDataModal={client}
                           onClose={() => closeViewUserModal()}/>
          <AddEditClientModal visible={addEditClientModalShow}
                              clientDataModal={client} isNew={isNew}
                              onClose={(refresh) => closeAddEditClientModal(refresh)}/>
          <TaskFormModal visible={addEditTaskClientModalShow}
                         task={products}
                         clientId={clientId}
                         onOk={() => {
                           closeAddEditTaskModal(true)
                         }}
                         isNew={true}
                         onCancel={() => closeAddEditTaskModal(false)}/>
          <DocumentsSignatureModal client={client}
                                   visible={visibleSendDocumentsModal}
                                   onClose={() => setVisibleSendDocumentsModal(false)}/>

          <ConnectMountainInsurance
            client={{
              firstName: client?.firstName || '',
              lastName: client?.lastName || ''
            }}
            userId={getUserId()}
            visible={connectMountainInsuranceModalShow}
            onClose={() => {
              setConnectMountainInsuranceModalShow(false)
            }}/>

          <Boxes
            visible={boxesModalShow}
            client={client}
            onClose={() => {
              setBoxesModalShow(false)
            }}
            onOk={() => {
              setBoxesModalShow(false)
            }}/>

          <Modal
            open={isSMSExistingClient}
            title="אישור עדכון פרטי עמית/ה"
            onCancel={() => {
              setIsSMSExistingClient(false);
            }}
            footer={[
              <Button key="no" onClick={async () => {
                setCreateClientSMSLoading(true);
                setIsSMSExistingClient(false);
                handleCreateClintSMS(client?._id, false);
              }}>
                לא
              </Button>,
              <Button key="yes" type="primary" onClick={async () => {
                setCreateClientSMSLoading(true);
                setIsSMSExistingClient(false);
                handleCreateClintSMS(client?._id, true);
              }}>
                כן
              </Button>
            ]}
          >
            <p>עמית זה קיים במערכת SMS, האם לעדכן את פרטי העמית/ה?</p>
          </Modal>

          <Modal
            open={isForceOrderModal}
            title="אישור הזמנת מסלקה"
            onCancel={() => {
              setOrderMislakaLoading(false);
              setIsForceOrderModal(false);
            }}
            footer={[
              <Button key="no" onClick={async () => {
                setOrderMislakaLoading(false);
                setIsForceOrderModal(false);
              }}>
                לא
              </Button>,
              <Button key="yes" type="primary" onClick={async () => {
                setIsForceOrderModal(false);
                handleOrderMislaka({...forceOrderData, forceOrder: true});
              }}>
                כן
              </Button>
            ]}
          >
            <p>הוזמן לעמית זה מסלקה ב 3 חודשים האחרונים, האם להזמין בכל זאת?</p>
          </Modal>

        </div>
      </React.Fragment>
    </Loader.LoadingContainer>
  );
};

export default ClientDetails;
