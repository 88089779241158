import React, {Component} from "react";

import {connect} from "react-redux";
import {
    Button
} from "reactstrap";

import {Link} from "react-router-dom";


// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import {withNamespaces} from "react-i18next";

// Redux Store
import {toggleRightSidebar} from "../../store/actions";

//Import logo Images
import logo from "../../assets/images/logo.png";
import logosm from "../../assets/images/logo-sm.png";


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isSocialPf: false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="#" className="logo logo-dark">
                                <span className="logo-sm" >
                                    <img src={logosm} alt="" height="45"/>
                                </span>
                                    <span className="logo-lg">
                                    <img src={logo} alt="" width="192" height="auto"/>
                                </span>
                                </Link>
                            </div>
                            <Button size="sm" color="none" type="button" onClick={this.toggleMenu}
                                    className="px-3 font-size-24 header-item waves-effect toggle-menu"
                                    id="vertical-menu-btn">
                                <i className="ri-menu-2-line align-middle"></i>
                            </Button>
                        </div>
                        <div className="float-right">

                        </div>
                        <div className="d-flex">
                            <ProfileMenu/>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {layoutType} = state.Layout;
    return {layoutType};
};

export default connect(mapStatetoProps, {toggleRightSidebar})(withNamespaces()(Header));
