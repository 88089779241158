import baseService from "../base";
import axios from "../axios";
export const updateClient = async (data: any) => {
  const url = baseService.baseUrl + baseService.endpoints.signature.updateClient;
  return await axios.PUT(url, data).catch();
}

export const updateAddressClient = async (data: any) => {
  const url = baseService.baseUrl + baseService.endpoints.signature.updateAddress;
  return await axios.PUT(url, data).catch();
}

export const createClient = async (data: any) => {
  const url = baseService.baseUrl + baseService.endpoints.signature.createClient;
  return await axios.POST(url, data).catch();
}

export const sign = async (data: any) => {
  const url = baseService.baseUrl + baseService.endpoints.signature.sign;
  return await axios.POST(url, data).catch();
}

export const uploadIdentityDocs = async (data: any) => {
  const url = baseService.baseUrl + baseService.endpoints.signature.identityDocs;
  const formData = new FormData();
  formData.append("frontFile", data.frontFile);
  formData.append("backFile", data.backFile);
  formData.append("attachmentFile", data.attachmentFile);
  formData.append("agentId", data.agentId || '');
  formData.append("companyId", data.companyId || '');
  formData.append("clientId", data.clientId || '');
  return await axios.POST(url, formData ,{ formData: true});
};
