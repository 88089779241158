import React from 'react';
import {Tag as TagAnt} from "antd";
import {LiteralUnion} from "antd/lib/_util/type";
import {PresetColorType, PresetStatusColorType} from "antd/lib/_util/colors";

interface Props {
  icon?: React.ReactNode;
  color?: LiteralUnion<PresetColorType | PresetStatusColorType>;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const Tag: React.FC<Props> = (props) => {
  return (
    <TagAnt
      icon={props.icon}
      className={props.className}
      style={props.style}
      color={props.color}>
      {props.children}
    </TagAnt>
  );
};

export default Tag;
