import React, {useEffect, useState} from 'react';
import {Button, Tooltip} from "antd";
import {
  PlusCircleOutlined, AuditOutlined,
} from "@ant-design/icons";
import Tag from '../../antDesign/Tag';
import type {ColumnsType} from 'antd/es/table';
import moment from "moment";
import Table from "../../antDesign/Table";
import TaskFormModal from "../../Tasks/components/TaskForm/TaskFormModal";

interface Props {
  isClient: boolean | undefined;
  clientId: string;
  productType: any;
  policies: any;
}

const Policies: React.FC<Props> = (props) => {

  const [dataSource, setDataSource] = useState<any>([])
  const [task, setTask] = useState<any>({})
  const [addEditTaskClientModalShow, setAddEditTaskClientModalShow] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>('');
  const [columns, setColumns] = useState<ColumnsType>([]);
  // @ts-ignore
  const sharedOnCell = (record, index) => {
    if (record.isTitle) {
      return {colSpan: 0};
    }
    return {};
  };

  const initColumns = () => {
    const columnsTable: ColumnsType = [
        {
          align: 'right',
          title: "שם יצרן",
          dataIndex: ['manufacturer', 'manufacturer_label'],
          key: 'manufacturer_label',
          onCell: (record, index) => {
            return {
              // @ts-ignore
              colSpan: record.isTitle && !props.isClient ? 7 : record.isTitle && props.isClient ? 6 : 1
            }
          },
        }, {
          align: 'right',
          title: "מוצר",
          dataIndex: 'productName',
          onCell: sharedOnCell,
          key: 'productName',
        }, {
          align: 'right',
          title: "פוליסה",
          dataIndex: 'policyNumberOrAccountNumber',
          onCell: sharedOnCell,
          key: 'policyNumberOrAccountNumber',
        }, {
          align: 'right',
          title: "מצב",
          dataIndex: ['statusPolicyOrAccount', 'id'],
          onCell: sharedOnCell,
          key: 'statusPolicyOrAccount',
          render: (_: any, record: any) => [
            <div key={record.key}>
              {
                record.statusPolicyOrAccount.id === '1' &&
                  <Tag color="green">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '2' &&
                  <Tag color="red">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '3' &&
                  <Tag color="magenta">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '4' &&
                  <Tag color="blue">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '5' &&
                  <Tag color="volcano">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '6' &&
                  <Tag color="orange">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '7' &&
                  <Tag color="geekblue">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '8' &&
                  <Tag color="gold">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '9' &&
                  <Tag color="cyan">{record.statusPolicyOrAccount.name}</Tag>
              }

              {
                record.statusPolicyOrAccount.id === '10' &&
                  <Tag color="purple">{record.statusPolicyOrAccount.name}</Tag>
              }
            </div>
          ]
        }, {
          align: 'right',
          title: "סוג תוכנית",
          dataIndex: ['programTypeOrAccount', 'name'],
          onCell: sharedOnCell,
          key: 'programTypeOrAccount',
        }, {
          align: 'right',
          title: "תאריך נכונות",
          dataIndex: 'correctnessDate',
          onCell: sharedOnCell,
          key: 'correctnessDate',
        }
      ];
    if (!props.isClient) {
        columnsTable.push({
          align: 'right',
          title: "פעולות",
          dataIndex: 'actions',
          onCell: sharedOnCell,
          key: 'actions',
          render: (_: any, record: any) => [
            <div key={record.key}>
              {
                (!props.isClient) &&
                  <Tooltip title='צור משימה'>
                      <Button
                          type='link'
                          onClick={() => {
                            if (props.productType.id === '0')
                              setTask({policy: record, productType: {...record.productType}});
                            else
                              setTask({policy: record, productType: props.productType});
                            setAddEditTaskClientModalShow(true);
                          }}>
                          <PlusCircleOutlined/>
                      </Button>
                  </Tooltip>
              }

              <Tooltip title='פוליסה'>
                <Button
                  type='link'
                  href={"/policy-details?pid=" + record.policyNumberOrAccountNumber}>
                  <AuditOutlined/>
                </Button>
              </Tooltip>
            </div>
          ]
        });
      }
    setColumns(columnsTable);
  }

  useEffect(() => {
    initColumns();
  }, [props.isClient])

  const loadDataSource = () => {
    setDataSource(props.policies.map((policy: any, index: number) => {
        return {
          key: index,
          manufacturer: {
            manufacturer_label: policy?.manufacturer?.manufacturer_name || '-'
          },
          productName: policy?.productName || '-',
          productType: policy?.productType || {},
          policyNumberOrAccountNumber: policy?.policyNumberOrAccountNumber || '-',
          privateCompany: policy?.privateCompany || '-',
          productJoinDate: policy?.productJoinDate || '-',
          programTypeOrAccount: policy?.programTypeOrAccount || '-',
          statusPolicyOrAccount: policy?.statusPolicyOrAccount || '-',
          manufacturerJoinDate: policy.manufacturerJoinDate ? moment(policy.manufacturerJoinDate).format("DD/MM/YYYY") : '-',
          correctnessDate: policy.correctnessDate ? moment(policy.correctnessDate).format("DD/MM/YYYY") : '-',
          isTitle: Object.keys(policy).length === 1
        }
      }))
  }

  const closeAddEditTaskModal = () => {
    setAddEditTaskClientModalShow(false);
  }

  useEffect(() => {
    loadDataSource();
    return () => {};
  }, [props.policies])

  useEffect(() => {
    setClientId(props.clientId);
    return () => {};
  }, [props.clientId])

  return (
    <>
      <Table
        rowKey='key'
        // @ts-ignore
        columns={columns}
        rowClassName={(record: any) => record.isTitle ? 'title-row-table' : ''}
        dataSource={dataSource}
        pagination={false}/>

      <TaskFormModal visible={addEditTaskClientModalShow}
                        task={task}
                        clientId={clientId}
                        isNew={true}
                        isSelected={true}
                        onOk={() => closeAddEditTaskModal()}
                        onCancel={() => closeAddEditTaskModal()}/>
    </>
  );
};

export default Policies;
