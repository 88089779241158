import React, {useEffect} from 'react';
import {Button, Col, DatePicker, Form, Input, Row, Typography} from "antd";
import PATTERNS from '../../../helpers/Patterns';
import AlertMessage from "../../../components/antDesign/Alert";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import GenderSelect from "../../../components/Selects/GenderSelect";
import FamilyStatus from "../../../components/Selects/FamilyStatus";

const {Text, Title} = Typography;

interface Props {
  client: any;
  firstNameAgent: string;
  lastNameAgent: string;
  onUpdateDetails: (client: any) => void;
  type: 'CLIENT' | 'AGENT' | 'PROMOTION' | '';
  error: {
    active: boolean,
    message: string
  };
}


const PersonalDetails: React.FC<Props> = (props) => {

  const [form] = Form.useForm();

  const handleUpdate = async (values: any) => {
    if (props.type === 'CLIENT') {
      values.id = props.client._id;
    }
    props.onUpdateDetails(values)
  }

  useEffect(() => {
    form.setFieldsValue({
      firstName: props.client?.firstName || '',
      lastName: props.client?.lastName || '',
      email: props.client?.email || '',
      clientId: props.client?.clientId || '',
      phone: props.client?.phone || '',
      dateOfBirth: props.client?.dateOfBirth ? moment(props.client?.dateOfBirth).tz('Asia/Tel_Aviv') : '',
      issueIdDate: props.client?.issueIdDate ? moment(props.client?.issueIdDate).tz('Asia/Tel_Aviv') : '',
      gender: props.client?.gender || {value: 0, text: 'בחירה'},
      familyStatus: props.client?.familyStatus || {value: 0, text: 'בחירה'},
      note: ''
    })
    return () => {
    };
  }, [props.client]);

  const handleGenderChange = (value: { value: string | number, text: string }) => {
    form.setFieldsValue({
      gender: value
    });
  };

  const handleFamilyStatusChange = (value: { value: string | number, text: string }) => {
    form.setFieldsValue({
      familyStatus: value
    });
  };

  return (
    <div className='personal-details'>
      {props.type === 'PROMOTION' &&
        <>
          <Title level={1} className={props.type === 'PROMOTION' ? 'promotion-title' : ''}>איזה יופי!</Title>
          <Title level={5} className={props.type === 'PROMOTION' ? 'promotion-title' : ''}>על בסיס התשובות שלך קיימת
            התכנות
            ללקיחת הלוואה</Title>
        </>
      }

      <Form
        form={form}
        layout="vertical"
        onFinish={handleUpdate}>

        <Row gutter={[16, 16]} style={{paddingBottom: 16}}>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="שם פרטי"
              initialValue={props.client && props.client.firstName ? props.client.firstName : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='firstName'>
              <Input placeholder="שם פרטי" size='large'/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="שם משפחה"
              initialValue={props.client && props.client.lastName ? props.client.lastName : ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='lastName'>
              <Input placeholder="שם משפחה" size='large'/>
            </Form.Item>
          </Col>

          {props.type !== 'PROMOTION' &&
            <Col lg={12} md={12} sm={12} xs={24}>
              <Form.Item
                label="תעודת זהות"
                initialValue={props.client && props.client.clientId ? props.client.clientId : ''}
                rules={[
                  {required: (props.type === 'AGENT'), message: "שדה חובה"},
                  {pattern: PATTERNS.NUMBER_ID, message: "מס׳ תעודת זהות לא חוקי"}]}
                name='clientId'>
                <Input disabled={props.type === 'CLIENT'} placeholder="תעודת זהות" size='large' type='number'/>
              </Form.Item>
            </Col>
          }

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="טלפון נייד"
              initialValue={props.client && props.client.phone ? props.client.phone : ''}
              rules={[{required: true, message: "שדה חובה"}, {
                pattern: PATTERNS.MOBILE,
                message: "טלפון לא חוקי"
              }]}
              name='phone'>
              <Input placeholder="טלפון נייד" size='large'/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="אימייל"
              initialValue={props.client && props.client.email ? props.client.email : ''}
              rules={[props.type === 'PROMOTION' ? {} : {required: true, message: "שדה חובה"}, {
                type: 'email',
                message: 'אימייל לא תקין'
              }]}
              name='email'>
              <Input placeholder="אימייל" size='large'/>
            </Form.Item>
          </Col>
          {props.type !== 'PROMOTION' &&
            <Col lg={12} md={12} sm={12} xs={24}>
              <Form.Item
                label="תאריך לידה"
                name='dateOfBirth'>
                <DatePicker size='large'
                            format='DD-MM-YYYY'
                            disabledDate={(current) => {
                              return (
                                moment() < current ||
                                moment().subtract(120, "year") >= current
                              );
                            }}/>
              </Form.Item>
            </Col>
          }

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="מין"
              initialValue={props.client && props.client.gender ? props.client.gender : 0}
              name='gender'>
              <GenderSelect
                onChange={handleGenderChange}
                value={props.client && props.client.gender ? props.client.gender : 0}
              />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="מצב משפחתי"
              initialValue={props.client && props.client.gender ? props.client.gender : 0}
              name='familyStatus'>
              <FamilyStatus
                onChange={handleFamilyStatusChange}
                value={props.client && props.client.familyStatus ? props.client.familyStatus : 0}
              />
            </Form.Item>
          </Col>

          {props.type === 'PROMOTION' && <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label="הערות"
              initialValue={''}
              name='note'>
              <TextArea rows={3} maxLength={300}/>
            </Form.Item>
          </Col>}

          {props.type !== 'PROMOTION' && <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="תאריך הנפקת תעודת זהות"
              name='issueIdDate'>
              <DatePicker size='large'/>
            </Form.Item>
          </Col>
          }

          {props.error.active &&
            <Col lg={24} md={24} sm={24} xs={24} style={{marginBottom: 10}}>
              <AlertMessage type={'error'} message={props.error.message} showIcon/>
            </Col>
          }

          {props.type === 'CLIENT' &&
            <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
              <div><Text> אלו הפרטים הקיימם אצל
                הסוכן {props.firstNameAgent + " " + props.lastNameAgent}</Text></div>
              <div><Text>במידה ואחד הנתונים אינו נכון נא עדכן כעת.</Text></div>
            </Col>
          }

          {props.type === 'AGENT' &&
            <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
              <div><Text>
                הסוכן {props.firstNameAgent + " " + props.lastNameAgent} שלח אלייך טופס הרשמה</Text></div>
              <div><Text>אנא מלא את הפרטים על מנת לסיים את התהליך</Text></div>
            </Col>
          }
        </Row>
        <Button type="primary" htmlType="submit" className='custom-button'>
          המשך
        </Button>
      </Form>
    </div>
  );
};

export default PersonalDetails;
