import React from 'react';
import {Select} from 'antd';

interface Props {
  value: { value: string | number | null | undefined, text: string } | undefined;
  onChange: (value: { value: number | string, text: string }) => void | undefined;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  allowClear?: boolean;
}

const GenderSelect: React.FC<Props> = ({
                                         value = {value: 0, text: 'בחירה'},
                                         onChange = undefined,
                                         disabled = false,
                                         style = {},
                                         className = "",
                                         allowClear = true,
                                       }) => {

  const genderOptions = [
    {value: 0, label: 'בחירה'},
    {value: 1, label: 'זכר'},
    {value: 2, label: 'נקבה'},
  ];

  const handleChange = (value: any) => {
    if (onChange) {
      const selected = genderOptions.find(option => option.value === value);
      onChange({
        value: selected?.value || 0,
        text: selected?.label || 'בחירה',
      });
    }
  }

  return (
    <Select
      value={value.value || 0}
      onChange={(option) => handleChange(option)}
      options={genderOptions}
      placeholder={'בחר מין'}
      disabled={disabled}
      allowClear={allowClear}
      style={{width: '100%', height: 36, ...style}}
      className={className}
      size={'large'}
    />
  );
};

export default GenderSelect;
