import React from 'react';
import {Col, Modal, Row, Typography} from "antd";
import moment from "moment";

const {Text, Title} = Typography;

interface Props {
  visible: boolean;
  clientDataModal: any;
  onClose: () => void;
}

const ViewClientModal: React.FC<Props> = (props) => {

  return (
    <Modal
      title={"פרטי עמית"}
      open={props.visible}
      onOk={() => props.onClose()}
      onCancel={() => props.onClose()}
      maskClosable={false}
      cancelButtonProps={{style: {display: 'none'}}}
      destroyOnClose
      okText="סגור"
      width={1000}>

      <Row>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <Title level={5}>כללי</Title>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>תעודת זהות</Text></div>
          <div><Text>{props.clientDataModal?.clientId || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>שם פרטי</Text></div>
          <div><Text>{props.clientDataModal?.firstName || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>שם משפחה</Text></div>
          <div><Text>{props.clientDataModal?.lastName || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>אימייל</Text></div>
          <div><Text>{props.clientDataModal?.email || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>טלפון נייד</Text></div>
          <div><Text>{props.clientDataModal?.phone || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>טלפון קווי</Text></div>
          <div><Text>{props.clientDataModal?.telephone || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>תאריך לידה</Text></div>
          <div>
            <Text>{props.clientDataModal?.dateOfBirth ? moment(props.clientDataModal.dateOfBirth).format('DD/MM/YYYY') : '-'}</Text>
          </div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>תאריך הנפקת תעודת זהות</Text></div>
          <div>
            <Text>{props.clientDataModal?.issueIdDate ? moment(props.clientDataModal.issueIdDate).format('DD/MM/YYYY') : '-'}</Text>
          </div>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <Title level={5}>כתובת</Title>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>מדינה</Text></div>
          <div><Text>{props.clientDataModal?.country || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>עיר</Text></div>
          <div><Text>{props.clientDataModal?.city || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>רחוב</Text></div>
          <div><Text>{props.clientDataModal?.street || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>מספר בית</Text></div>
          <div><Text>{props.clientDataModal?.houseNumber || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>מיקוד</Text></div>
          <div><Text>{props.clientDataModal?.postalCode || '-'}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>הערות</Text></div>
          <div><Text>{props.clientDataModal?.note || '-'}</Text></div>
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <Title level={5}>מערכת</Title>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>נוצר על ידי</Text></div>
          <div><Text>{
            props.clientDataModal?.createdBy && props.clientDataModal?.createdBy.firstName &&
            props.clientDataModal?.createdBy.lastName ? (props.clientDataModal?.createdBy.firstName + ' ' +
              props.clientDataModal?.createdBy.lastName) : "-"}</Text></div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>תאריך יצירה</Text></div>
          <div>
            <Text>{props.clientDataModal && props.clientDataModal.createdAt ? moment(props.clientDataModal.createdAt).format('DD/MM/YYYY') : '-'}</Text>
          </div>
        </Col>

        <Col lg={8} md={12} sm={12} xs={12} className="my-2">
          <div><Text strong>תאריך עדכון</Text></div>
          <div>
            <Text>{props.clientDataModal && props.clientDataModal.updatedAt ? moment(props.clientDataModal.updatedAt).format('DD/MM/YYYY') : '-'}</Text>
          </div>
        </Col>
      </Row>

    </Modal>
  );
};

export default ViewClientModal;
