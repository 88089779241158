import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Empty, Row, Space, Spin, Tooltip, Typography, Upload} from 'antd';
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  UploadOutlined
} from '@ant-design/icons';
import type {UploadFile} from 'antd/es/upload/interface';
import Messages from '../../../../../antDesign/Message';
import {getIdentityDocs, uploadIdentityDocs} from '../../../../../../services/client';
import file from "../../../../../../helpers/file";

const {Text, Title} = Typography;

interface IdentityProps {
  clientId?: string;
}

interface IdentityDoc {
  _id: string
  type: string;
  fileName: string;
  contentType: string;
  fileContent: string;
  createdAt: string;
}

interface IdentityDocs {
  front: IdentityDoc | undefined;
  back: IdentityDoc | undefined;
  attachment: IdentityDoc | undefined;
}

const Identity: React.FC<IdentityProps> = ({clientId}) => {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<IdentityDocs | null>(null);
  const [editingFile, setEditingFile] = useState<'FRONT' | 'BACK' | 'ATTACHMENT' | null>(null);
  const [singleUploadFile, setSingleUploadFile] = useState<UploadFile | null>(null);

  const fetchDocuments = async () => {
    if (!clientId) return;

    setLoading(true);
    try {
      const response = await getIdentityDocs(clientId);
      if (response.status === 'SUCCESS') {
        setDocuments(response.documents);
      }
    } catch (error) {
      Messages.Error('שגיאה בטעינת מסמכים').then();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDocuments();
    })();
  }, [clientId]);

  const handleDownload = (doc: IdentityDoc | undefined) => {
    if (doc) {
      const fileName = doc.type === 'FRONT' ? 'צד קדמי' : doc.type === 'BACK' ? 'צד אחורי' : 'ספח';
      file.download(fileName, doc.contentType, doc.fileContent);
    }
  }

  const handleView = (doc: IdentityDoc | undefined) => {
    if (doc) {
      file.view(doc.contentType, doc.fileContent);
    }
  }

  const handleSingleFileUpload = async (fileId: string) => {
    if (!clientId || !singleUploadFile || !editingFile) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', singleUploadFile as any);
      formData.append('clientId', clientId);
      formData.append('type', editingFile);
      formData.append('fileId', fileId);

      const response = await uploadIdentityDocs(formData);

      if (response.status === 'SUCCESS') {
        Messages.Success('מסמך הועלה בהצלחה').then();
        setEditingFile(null);
        setSingleUploadFile(null);
        await fetchDocuments();
      }
    } catch (error: any) {
      Messages.Error(error.displayMessage || 'שגיאה בהעלאת מסמך').then();
    } finally {
      setLoading(false);
    }
  };

  const renderDocumentList = () => {
    if (!documents) {
      return (
        <Card className="w-full text-center">
          <Empty
            description="לא נמצאו מסמכי תעודת זהות"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          >
            <Button type="primary" onClick={() => setDocuments({
                attachment: undefined,
                back: undefined,
                front: undefined
              }
            )}>
              העלאת מסמכים
            </Button>
          </Empty>
        </Card>
      );
    }

    return (
      <Space direction="vertical" className="w-full" size="middle">
        <Row className="mb-4" justify="space-between" align="middle">
          <Col>
            <Title level={5} style={{margin: 0}}>מסמכי תעודת זהות</Title>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card className="identity-card"
                  cover={documents.front ? (
                      documents.front?.contentType.includes('image') ? (
                        <img
                          alt="תצוגה מקדימה"
                          src={`data:${documents.front.contentType};base64,${documents.front.fileContent}`}
                        />
                      ) : (
                        <div className="pdf-img">
                          <FilePdfOutlined/>
                        </div>
                      )) :
                    (
                      <div style={{
                        height: '200px',
                        background: '#fafafa',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '8px'
                      }}>
                        <FileImageOutlined style={{fontSize: '48px', color: '#d9d9d9'}}/>
                        <Text type="secondary">לא נמצא קובץ</Text>
                      </div>
                    )}
                  actions={[
                    <Tooltip title="צפה במסמך">
                      <Button
                        type="text"
                        icon={<EyeOutlined/>}
                        onClick={() => handleView(documents.front)}
                        disabled={!documents.front}
                      />
                    </Tooltip>,
                    <Tooltip title="הורד מסמך">
                      <Button
                        type="text"
                        icon={<DownloadOutlined/>}
                        onClick={() => handleDownload(documents.front)}
                        disabled={!documents.front}
                      />
                    </Tooltip>,
                    <Tooltip title="ערוך מסמך">
                      <Button
                        type="text"
                        icon={<EditOutlined/>}
                        onClick={() => {
                          if (editingFile === 'FRONT') {
                            setEditingFile(null);
                          } else {
                            setEditingFile('FRONT');
                          }
                          setSingleUploadFile(null);
                        }}
                      />
                    </Tooltip>
                  ]}
            >
              <Card.Meta
                title="צד קדמי של תעודת זהות"
              />
              {editingFile === 'FRONT' && (
                <div className="mt-4">
                  <Upload
                    maxCount={1}
                    beforeUpload={(file) => {
                      setSingleUploadFile(file);
                      return false;
                    }}
                    accept="image/*,.pdf"
                  >
                    <Button icon={<UploadOutlined/>}>
                      בחר קובץ חדש
                    </Button>
                  </Upload>
                  {singleUploadFile && (
                    <Space className="mt-2">
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleSingleFileUpload(documents?.front?._id || '')}
                        loading={loading}
                      >
                        עדכן
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setEditingFile(null);
                          setSingleUploadFile(null);
                        }}
                      >
                        ביטול
                      </Button>
                    </Space>
                  )}
                </div>
              )}
            </Card>
          </Col>
          <Col span={8}>
            <Card className="identity-card"
                  cover={documents.back ? (
                      documents.back?.contentType.includes('image') ? (
                        <img
                          alt="תצוגה מקדימה"
                          src={`data:${documents.back.contentType};base64,${documents.back.fileContent}`}
                        />
                      ) : (
                        <div className="pdf-img">
                          <FilePdfOutlined/>
                        </div>
                      )) :
                    (
                      <div style={{
                        height: '200px',
                        background: '#fafafa',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '8px'
                      }}>
                        <FileImageOutlined style={{fontSize: '48px', color: '#d9d9d9'}}/>
                        <Text type="secondary">לא נמצא קובץ</Text>
                      </div>
                    )}
                  actions={[
                    <Tooltip title="צפה במסמך">
                      <Button
                        type="text"
                        icon={<EyeOutlined/>}
                        onClick={() => handleView(documents.back)}
                        disabled={!documents.back}
                      />
                    </Tooltip>,
                    <Tooltip title="הורד מסמך">
                      <Button
                        type="text"
                        icon={<DownloadOutlined/>}
                        onClick={() => handleDownload(documents.back)}
                        disabled={!documents.back}
                      />
                    </Tooltip>,
                    <Tooltip title="ערוך מסמך">
                      <Button
                        type="text"
                        icon={<EditOutlined/>}
                        onClick={() => {
                          if (editingFile === 'BACK') {
                            setEditingFile(null)
                            setSingleUploadFile(null)
                          } else {
                            setEditingFile('BACK')
                          }
                          setSingleUploadFile(null);
                        }}
                      />
                    </Tooltip>
                  ]}
            >
              <Card.Meta
                title="צד אחורי של תעודת זהות"
              />
              {editingFile === 'BACK' && (
                <div className="mt-4">
                  <Upload
                    maxCount={1}
                    beforeUpload={(file) => {
                      setSingleUploadFile(file);
                      return false;
                    }}
                    accept="image/*,.pdf"
                  >
                    <Button icon={<UploadOutlined/>}>
                      בחר קובץ חדש
                    </Button>
                  </Upload>
                  {singleUploadFile && (
                    <Space className="mt-2">
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleSingleFileUpload(documents?.back?._id || '')}
                        loading={loading}
                      >
                        עדכן
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setEditingFile(null);
                          setSingleUploadFile(null);
                        }}
                      >
                        ביטול
                      </Button>
                    </Space>
                  )}
                </div>
              )}
            </Card>
          </Col>
          <Col span={8}>
            <Card className="identity-card"
                  cover={documents.attachment ? (
                      documents.attachment?.contentType.includes('image') ? (
                        <img
                          alt="תצוגה מקדימה"
                          src={`data:${documents.attachment.contentType};base64,${documents.attachment.fileContent}`}
                        />
                      ) : (
                        <div className="pdf-img">
                          <FilePdfOutlined/>
                        </div>
                      )) :
                    (
                      <div style={{
                        height: '200px',
                        background: '#fafafa',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '8px'
                      }}>
                        <FileImageOutlined style={{fontSize: '48px', color: '#d9d9d9'}}/>
                        <Text type="secondary">לא נמצא קובץ</Text>
                      </div>
                    )
                  }
                  actions={[
                    <Tooltip title="צפה במסמך">
                      <Button
                        type="text"
                        icon={<EyeOutlined/>}
                        onClick={() => handleView(documents.attachment)}
                        disabled={!documents.attachment}
                      />
                    </Tooltip>,
                    <Tooltip title="הורד מסמך">
                      <Button
                        type="text"
                        icon={<DownloadOutlined/>}
                        onClick={() => handleDownload(documents.attachment)}
                        disabled={!documents.attachment}
                      />
                    </Tooltip>,
                    <Tooltip title="ערוך מסמך">
                      <Button
                        type="text"
                        icon={<EditOutlined/>}
                        onClick={() => {
                          if (editingFile === 'ATTACHMENT') {
                            setEditingFile(null);
                          } else {
                            setEditingFile('ATTACHMENT');
                          }
                          setSingleUploadFile(null);
                        }}
                      />
                    </Tooltip>
                  ]}
            >
              <Card.Meta
                title="ספח תעודת זהות"
              />
              {editingFile === 'ATTACHMENT' && (
                <div className="mt-4">
                  <Upload
                    maxCount={1}
                    beforeUpload={(file) => {
                      setSingleUploadFile(file);
                      return false;
                    }}
                    accept="image/*,.pdf"
                  >
                    <Button icon={<UploadOutlined/>}>
                      בחר קובץ חדש
                    </Button>
                  </Upload>
                  {singleUploadFile && (
                    <Space className="mt-2">
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => handleSingleFileUpload(documents.attachment?._id || '')}
                        loading={loading}
                      >
                        עדכן
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setEditingFile(null);
                          setSingleUploadFile(null);
                        }}
                      >
                        ביטול
                      </Button>
                    </Space>
                  )}
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Space>
    );
  };

  if (!clientId) {
    return <Text>לא נמצא מזהה לקוח</Text>;
  }

  return (
    <Spin spinning={loading} tip="טוען...">
      <div className="p-4">
        {renderDocumentList()}
      </div>
    </Spin>
  );
};

export default Identity;
