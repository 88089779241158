import React, {Component} from 'react';
import {
    Button,
    Col,
    FormGroup,
    Label,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";

//i18n
import {withNamespaces} from 'react-i18next';
//import {AvField, AvForm} from "availity-reactstrap-validation";

import {getEmployersTypes, createEmployer, updateEmployer} from '../../../services/admiringFactor/employersService';


class EmployerModal extends Component {
    constructor(props) {
        super(props);
        let self = this;

        this.state = {
            showSuccessMsg: false,
            showErrorMsg: false,
            errorMsgText: '',
            types: []
        };

        getEmployersTypes().then((types) => {
            self.state.types = types.list;
        });

        this.handleUpdateEmployer = this.handleUpdateEmployer.bind(this);
        this.handleCreateEmployer = this.handleCreateEmployer.bind(this);
        this.handleTypeSelectChange = this.handleTypeSelectChange.bind(this);
    }

    async handleUpdateEmployer(event, values) {
        try {
            let employer = values;
            employer.type.name = values !== '' ? this.state.types.filter((type) => {
                return type.id.toString() === values.type.id.toString();
            })[0].name : '';

            await updateEmployer({employer});
            this.setState({showSuccessMsg: true});
        } catch (error) {
            this.setState({showErrorMsg: true, errorMsgText: error});
        }
    }

    async handleCreateEmployer(event, values) {
        try {
            let employer = values;
            employer.type.name = values !== '' ? this.state.types.filter((type) => {
                return type.id.toString() === values.type.id;
            })[0].name : '';

            await createEmployer({employer});
            this.setState({showSuccessMsg: true});
        } catch (error) {
            this.setState({showErrorMsg: true, errorMsgText: error});
        }
    }

    handleTypeSelectChange(event, values) {
        this.props.employerDataModal.type.id = values;
        this.forceUpdate();
    }

    render() {
        let form = this.props.employerDataModal;

        return (
            <Modal
                isOpen={this.props.EmployerModalShow}
                toggle={this.tog_static}
                backdrop="static"
                centered
                size="lg"
            >
                <ModalHeader toggle={() => this.props.closeEmployerModal(false)}>
                    {this.props.isNew === true ? this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.ADD") : this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.EDIT")}
                </ModalHeader>

               {/* <AvForm onValidSubmit={this.props.isNew ? this.handleCreateEmployer : this.handleUpdateEmployer}
                        model={form}>
                    <AvField name="employerId" value={form._id}
                             type="hidden" className="form-control"
                             id="employerId"/>
                    <ModalBody>
                        <Row>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="type">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.TYPE")} *</Label>
                                    <AvField type="select" id="type.id" name="type.id" value={form.type.id}
                                             onChange={this.handleTypeSelectChange}
                                             validate={{required: true}}
                                             errorMessage={this.props.t("VALIDATION.REQUIRED")}>
                                        <option key={""} value="">{this.props.t("GENERAL.CHOOSE")}</option>
                                        {this.state.types.map(type => {
                                            return <option key={type.id} value={type.id}>{type.name}</option>
                                        })}
                                    </AvField>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="idNumber">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ID_NUMBER")} *</Label>
                                    <AvField name="idNumber" value={form.idNumber}
                                             type="number" className="form-control"
                                             id="idNumber"
                                             disabled={!this.props.isNew}
                                             validate={{required: true}}
                                             errorMessage={this.props.t("VALIDATION.REQUIRED")}
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ID_NUMBER")}/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <Label
                                    htmlFor="name">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.NAME")} *</Label>
                                <AvField name="name" value={form.phone}
                                         type="text" className="form-control"
                                         id="name"
                                         validate={{required: true}}
                                         errorMessage={this.props.t("VALIDATION.REQUIRED")}
                                         placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.NAME")}/>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className="pt-2">
                                <FormGroup>
                                    <Label
                                        className="text-secondary">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.CONTACT_TITLE")}</Label>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.firstName">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_FIRST_NAME")}</Label>
                                    <AvField name="contact.firstName" value={form.contact.firstName}
                                             type="text" className="form-control"
                                             id="contact.firstName"
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_FIRST_NAME")}/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.lastName">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_LAST_NAME")}</Label>
                                    <AvField name="contact.lastName" value={form.contact.lastName}
                                             type="text" className="form-control"
                                             id="contact.lastName"
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_LAST_NAME")}/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.mobile">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_MOBILE")}</Label>
                                    <AvField name="contact.mobile" value={form.contact.mobile}
                                             type="text" className="form-control"
                                             id="contact.mobile"
                                             validate={{pattern: {value: /^05\d([-]{0,1})\d{7}$/}}}
                                             errorMessage={this.props.t("VALIDATION.PHONE_NOT_VALID")}
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_MOBILE")}/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.phone">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_PHONE")}</Label>
                                    <AvField name="contact.phone" value={form.contact.phone}
                                             type="text" className="form-control"
                                             id="contact.phone"
                                             validate={{pattern: {value: /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/}}}
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_PHONE")}/>
                                </FormGroup>
                            </Col>

                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.email">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_EMAIL")}</Label>
                                    <AvField name="contact.email" value={form.contact.email}
                                             type="text" className="form-control"
                                             id="contact.email"
                                             validate={{email: true}}
                                             errorMessage={this.props.t("VALIDATION.EMAIL_NOT_VALID")}
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CONTACT_EMAIL")}/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.address">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ADDRESS")}</Label>
                                    <AvField name="contact.address" value={form.contact.address}
                                             type="text" className="form-control"
                                             id="contact.address"
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ADDRESS")}/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.city">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CITY")}</Label>
                                    <AvField name="contact.city" value={form.contact.city}
                                             type="text" className="form-control"
                                             id="contact.city"
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.CITY")}/>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={6} className="pt-2">
                                <FormGroup>
                                    <Label
                                        htmlFor="contact.zipCode">{this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ZIP_CODE")}</Label>
                                    <AvField name="contact.zipCode" value={form.contact.zipCode}
                                             type="number" className="form-control"
                                             id="contact.zipCode"
                                             placeholder={this.props.t("ADMIRING_FACTOR.EMPLOYERS.EMPLOYER_MODAL.FORM.ZIP_CODE")}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit"
                                color="primary">{this.props.isNew ? this.props.t("BUTTON.ADD") : this.props.t("BUTTON.SAVE")}</Button>
                        <Button type="button" color="light"
                                onClick={() => this.props.closeEmployerModal(false)}>{this.props.t("BUTTON.CANCEL")}</Button>
                    </ModalFooter>
                </AvForm>*/}
            </Modal>
        );
    }
}

export default withNamespaces()(EmployerModal);
