import React, {useEffect} from 'react';
import {Button, Col, Form, Input, Row, Typography} from "antd";
import AlertMessage from "../../../components/antDesign/Alert";

const {Text} = Typography;

interface Props {
  client: any;
  onUpdateDetails: (client: any) => void;
  error: {
    active: boolean,
    message: string
  };
}

const AddressDetails: React.FC<Props> = (props) => {

  const [form] = Form.useForm();

  const handleUpdate = async (values: any) => {
    values.id = props.client._id;
    props.onUpdateDetails(values)
  }

  useEffect(() => {
    form.setFieldsValue({
      city: props.client?.city || '',
      street: props.client?.street || '',
      houseNumber: props.client?.houseNumber || '',
      apartmentNumber: props.client?.apartmentNumber || '',
      postalCode: props.client?.postalCode || ''
    })
    return () => {
    };
  }, [props.client])

  return (
    <div className='personal-details'>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleUpdate}>

        <Row gutter={[16, 16]} style={{paddingBottom: 16}}>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="עיר"
              initialValue={props.client?.city || ''}
              rules={[{required: true, message: "שדה חובה"}]}
              name='city'>
              <Input placeholder="עיר" size='large'/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="רחוב"
              initialValue={props.client?.street || ''}
              name='street'>
              <Input placeholder="רחוב" size='large'/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="מס׳ בית"
              initialValue={props.client?.houseNumber || ''}
              name='houseNumber'>
              <Input placeholder="מס׳ בית" size='large' type='number'/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="מס׳ דירה"
              initialValue={props.client?.apartmentNumber || ''}
              name='apartmentNumber'>
              <Input placeholder="מס׳ דירה" size='large' type='number'/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="מיקוד"
              initialValue={props.client?.apartmentNumber || ''}
              name='postalCode'>
              <Input placeholder="מיקוד" size='large' type='number'/>
            </Form.Item>
          </Col>


          {props.error.active &&
            <Col lg={24} md={24} sm={24} xs={24} style={{marginBottom: 10}}>
              <AlertMessage type={'error'} message={props.error.message} showIcon/>
            </Col>
          }

          <Col lg={24} md={24} sm={24} xs={24} className='text-center'>
            <div><Text>אנא מלא את הפרטים על מנת להמשיך את התהליך</Text></div>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" className='custom-button'>
          המשך
        </Button>
      </Form>
    </div>
  );
};

export default AddressDetails;
