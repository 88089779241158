import React from 'react';
import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Space, Tooltip, Upload} from 'antd';
import {EyeOutlined, UploadOutlined} from '@ant-design/icons';
import file from "../../../../helpers/file";

interface DynamicFieldProps {
  manufacturers: { value: string, label: string }[];
  field: TASKS.TaskField;
  isNew: boolean;
  existingFile?: {
    name: string;
    contentType: string;
    fileData: string;
  };
}

const productsList = [
  {id: '1', label: 'ביטוח מנהלים', type: 'pension'},
  {id: '2', label: 'מנהלים חיסכון טהור', type: 'pension'},
  {id: '3', label: 'פנסיה ותקיה', type: 'pension'},
  {id: '4', label: 'פנסיה כללית/משלימה', type: 'pension'},
  {id: '5', label: 'פנסיה מקיפה', type: 'pension'},
  {id: '6', label: 'פנסיה קצבה', type: 'pension'},
  {id: '7', label: 'גמל לתגמולים ופיצויים', type: 'finance'},
  {id: '8', label: 'גמל להשקעה', type: 'finance'},
  {id: '9', label: 'השתלמות', type: 'finance'},
  {id: '10', label: 'איחוד קופות', type: 'finance'},
  {id: '11', label: 'חיסכון פרט', type: 'finance'},
  {id: '12', label: 'ניהול תיקים', type: 'finance'},
  {id: '13', label: 'השקעות אלטרנטיביות', type: 'finance'},
  {id: '14', label: 'א.כ.ע שלב מפעלי', type: 'risk'},
  {id: '15', label: 'מטריה ביטוחית', type: 'risk'},
  {id: '16', label: 'א.כ.ע', type: 'risk'},
  {id: '17', label: 'ביטוח משכנתא', type: 'risk'},
  {id: '18', label: 'ביטוח חיים', type: 'risk'},
  {id: '19', label: 'ביטוח בריאות', type: 'risk'},
  {id: '20', label: 'ביטוח מחלות קשות', type: 'risk'},
  {id: '21', label: 'ביטוח סיעוד', type: 'risk'},
  {id: '22', label: 'ביטוח נסיעות חו"ל', type: 'risk'},
  {id: '23', label: 'השקעה אלטרנטיבית', type: 'risk'},
  {id: '99', label: 'כללי', type: 'general'},
].sort((a, b) => a.label.localeCompare(b.label));

export const DynamicField: React.FC<DynamicFieldProps> = ({field, isNew, existingFile, manufacturers}) => {
  const [newUpload, setNewUpload] = React.useState<boolean>(false);

  const normFile = (e: any) => {
    if (Array.isArray(e)) return e;
    return e?.fileList;
  };
  const renderField = () => {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'tel':
        const rules: any = field.required ? [{required: true, message: 'שדה חובה'}] : [];
        if (field.type === 'email') rules.push({type: 'email', message: 'אימייל לא חוקי'});
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={rules}>
            <Input/>
          </Form.Item>
        );
      case 'number':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <InputNumber/>
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <DatePicker placeholder="" format="DD/MM/YYYY"/>
          </Form.Item>
        );
      case 'file':
        return (
          <Form.Item
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}
            valuePropName="fileList"
            name={["dynamic_fields", field.name]}
            getValueFromEvent={normFile}>
            {isNew || newUpload ?
              <Upload
                name="files"
                maxCount={1}
                beforeUpload={() => false}>
                <Button icon={<UploadOutlined/>}>טעינת קובץ</Button>
              </Upload> :
              (<Space wrap>
                <div>{existingFile?.name}</div>
                <Tooltip title='צפייה בקובץ'>
                  <EyeOutlined style={{color: '#10bb69'}} onClick={() => {
                    if (existingFile?.contentType) {
                      const bufferData = JSON.parse(existingFile.fileData);
                      const uint8Array = new Uint8Array(bufferData.data);
                      const base64String = btoa(
                        uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '')
                      );
                      file.view(existingFile?.contentType || 'application/pdf', base64String);
                    }
                  }}/>
                </Tooltip>
                <Tooltip title='טען אחר'>
                  <UploadOutlined style={{color: '#10bb69'}} onClick={() => setNewUpload(true)}/>
                </Tooltip>
              </Space>)
            }
          </Form.Item>
        );
      case 'radio':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <Radio.Group>
              {field.items?.map((item) => (
                <Radio key={item.id} value={item.id}>{item.label}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case 'checkbox':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            valuePropName="checked"
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <Checkbox>{field.label}</Checkbox>
          </Form.Item>
        );
      case 'select':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <Select>
              {field.items?.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case 'products-pension-list':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <Select>
              {productsList.filter(pro => ['pension', 'general'].includes(pro.type))?.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
              ))}
            </Select>

          </Form.Item>
        );
      case 'products-risk-list':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <Select>
              {productsList.filter(pro => ['risk', 'general'].includes(pro.type))?.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case 'products-finance-list':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <Select>
              {productsList.filter(pro => ['finance', 'general'].includes(pro.type))?.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      case 'manufacturers-list':
        return (
          <Form.Item
            name={["dynamic_fields", field.name]}
            label={field.label}
            rules={field.required ? [{required: true, message: 'שדה חובה'}] : []}>
            <Select
              showSearch
              optionFilterProp={"label"}
              options={manufacturers.map((item: any) => {
                return {value: item.value, label: item.label}
              })}
            >

            </Select>
          </Form.Item>
        );
      default:
        return null;
    }
  };
  return renderField();
};
