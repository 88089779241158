import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row} from "antd";
import AddEditEvent from "./components/modals/addEditEvent";
import Loader from "../antDesign/Loader";
import Messages from "../antDesign/Message";
import moment from "moment";

// Calendar
//import {Calendar} from '@fullcalendar/core';
// @ts-ignore
//import heLocale from '@fullcalendar/core/locales/he';
/*import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';*/

/*import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/bootstrap/main.css";*/

// services
import {getMeetings, updateMeeting} from "../../services/meeting";

const Index: React.FC = () => {
  const [addEditEventModalVisible, setAddEditEventModalVisible] = useState<boolean>(false);
  const [addEditEventModalData, setAddEditEventModalData] = useState<CALENDAR.CalendarResult | undefined>(undefined);
  const [isNew, setNew] = useState<boolean>(false);
  const [isClickOnBoard, setClickOnBoard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
 // const [calendar, setCalendar] = useState<Calendar>();

  useEffect(() => {
    /*let calendarEl: HTMLElement = document.getElementById('calendar')!;
    setCalendar(new Calendar(calendarEl, {
      plugins: [BootstrapTheme, dayGridPlugin, interactionPlugin],
      defaultView: "dayGridMonth",
      timeZone: 'asia/jerusalem',
      locales: heLocale,
      locale: 'he',
      themeSystem: "bootstrap",
      editable: true,
      droppable: true,
      eventLimit: true,
      selectable: true,
      selectMirror: true,
      allDayDefault: false,
      displayEventTime: false,
      header: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek,dayGridDay",
      },
      select: (value: any) => {
        setNew(true);
        setClickOnBoard(true);
        setAddEditEventModalData({
          start: value.start,
          end: value.end,
        });
        setAddEditEventModalVisible(true);
      },
      eventClick: (info) => {
        setNew(false);
        setClickOnBoard(false);
        setAddEditEventModalData({
          id: info.event.id,
          title: info.event.title,
          start: info.event.start,
          end: info.event.end,
        });
        setAddEditEventModalVisible(true);
      },
      events: (values, successCallback, failureCallback) => {
        getMeetings(values).then((response) => {
          setLoading(true);
          if (response.status.toString() === 'SUCCESS') {
            successCallback(
              response.list.map((meeting: any) => {
                return {
                  id: meeting._id,
                  title: meeting.title + " " + meeting.start_time + ' - ' + meeting.end_time,
                  start: new Date().setDate(moment(meeting.start_date).date()),
                  end: new Date().setDate(moment(meeting.end_date).date()),
                  color: meeting.color
                }
              })
            )
          }
          setLoading(false);
        })
      },
      eventDrop: (info) => {
        const body: any = {
          meeting_id: info.event.id,
          start_date: info.event.start,
          end_date: info.event.end,
          drag_and_drop: true
        }

        updateMeeting(body).then(response => {
          if (response.status.toString() === 'SUCCESS') {
            Messages.Success('הפעולה בוצעה בהצלחה').then(() => {
            });
          }else {
            Messages.Error('הפעולה נכשלה').then(() => {
            });
          }
        }).catch(() => {
          Messages.Error('הפעולה נכשלה').then(() => {
          });
        })
      }
    }));*/
    return () => {};
  }, [])

 /* useEffect(() => {
    if (calendar)
      calendar.render();
  }, [calendar])*/

  return (
    <React.Fragment>
      <div className="page-content">

        <Row className='mb-4'>
          <Col xs={24}>
            <Card>
              <Button type="primary"
                      style={{height: 40}}
                      onClick={() => {
                        setNew(true)
                        setClickOnBoard(false);
                        setAddEditEventModalData(undefined);
                        setAddEditEventModalVisible(true);
                      }}>
                <i className="mdi mdi-plus mr-2"/>צור פגישה
              </Button>
            </Card>
          </Col>
        </Row>

        <Loader.LoadingContainer loading={loading} tip={'טוען...'}>
          <Row>
            <Col xs={24}>
              <Card>
                <div id='calendar'/>
              </Card>
            </Col>
          </Row>
        </Loader.LoadingContainer>

        <AddEditEvent
          visible={addEditEventModalVisible}
          isNew={isNew}
          isClickOnBoard={isClickOnBoard}
          selected={addEditEventModalData}
          onConfirm={() => {
            Messages.Success('הפעולה בוצעה בהצלחה').then(() => {});
           // calendar?.refetchEvents();
            setAddEditEventModalVisible(false);
          }}
          onClose={() => setAddEditEventModalVisible(false)}
        />
      </div>
    </React.Fragment>
  );
};

export default Index;
