import React, {useRef} from 'react';
import {ActionType, ProColumns} from "@ant-design/pro-components";
import {getClientDetailsSignatureDocuments} from "../../../../../../services/client";
import {Button, Tag, Tooltip} from "antd";
import file from "../../../../../../helpers/file";
import {DownloadOutlined, EyeOutlined} from "@ant-design/icons";
import Table from "../../../../../antDesign/ProTable";

interface Props {
  clientId: string;
}

const Signature: React.FC<Props> = (props) => {

  const actionRefSignature = useRef<ActionType>();

  const onDownloadSignatureDocumentPressed = (doc: any) => {
    if (doc.fileData) {
      file.download(doc.appendixType, doc.contentType || 'application/pdf', doc.fileData);
    }
  }

  const columnsSignature: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.idType ? a.idType.localeCompare(b.idType) : false,
      title: "סוג מזהה",
      dataIndex: 'appendixType',
      fieldProps: {
        placeholder: 'סוג מזהה'
      },
      hideInSearch: false,
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.isSigned ? a.isSigned.localeCompare(b.isSigned) : false,
      title: "מצב",
      fieldProps: {
        placeholder: 'מצב'
      },
      dataIndex: 'isSigned',
      hideInSearch: false,
      render: (_: any, record: any) => [
        <div key={record._id}>
          {
            record.isSigned &&
            <Tag color="success">חתום</Tag>
          }

          {
            !record.isSigned &&
            <Tag color="error">לא חתום</Tag>
          }
        </div>
      ]
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      hideInSearch: true,
      render: (_: any, record: any) => [
        <div key={record._id}>
          {
            record.isSigned &&
            <>
              <Tooltip title='צפייה במסמך'>
                <Button
                  type='link'
                  onClick={() => {
                    if (record.contentType) {
                      file.view(record.contentType || 'application/pdf', record.fileData);
                    }
                  }}>
                  <EyeOutlined/>
                </Button>
              </Tooltip>

              <Tooltip title='הורדת המסמך'>
                <Button
                  type='link'
                  onClick={() => {
                    onDownloadSignatureDocumentPressed(record)
                  }}>
                  <DownloadOutlined/>
                </Button>
              </Tooltip>
            </>
          }

          {
            !record.isSigned &&
            <>-</>
          }
        </div>
      ]
    }
  ];

  const loadDataTableSignature = async (params: any) => {
    params.clientId = props.clientId;
    return await getClientDetailsSignatureDocuments(params).then((response) => {
      const dataTable = response.signatureDocuments.appendixDocuments.map((signatureDocument: any, index: number) => {
        return {
          _id: index,
          appendixType: signatureDocument.appendixType === "appendixA" ? 'נספח א׳ (מסלקה)' :
            signatureDocument.appendixType === "appendixB" ? 'נספח ב׳ (חברות ביטוח)' :
              signatureDocument.appendixType === "appendixE" ? 'נספח ה׳ (הר הביטוח)' : undefined,
          contentType: signatureDocument.contentType,
          fileData: signatureDocument.fileData,
          isSigned: signatureDocument.isSigned,
        }
      })

      return {
        status: response.status,
        current: response.current,
        total: response.total,
        pageSize: response.pageSize,
        data: dataTable,
      };
    })
  }

  return (
    <Table
      actionRef={actionRefSignature}
      request={loadDataTableSignature}
      columns={columnsSignature}
    />
  );
};

export default Signature;
