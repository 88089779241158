import React from 'react';
import {Link} from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import {Col, Row} from "antd";

interface Props {
  children: React.ReactNode,
}

const Main: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <Row className="no-gutters">
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col xl={21} lg={21} md={21} sm={21} xs={21}>
                  <div>
                    <div className="text-center" style={{ margin: '16px 16px 60px 0'}}>
                      <img src={logo} alt="logo" width={260}/>
                    </div>
                    {props.children}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <Col xl={16} lg={16} md={0} sm={0} xs={0}>
          <div className="authentication-bg">
            <div className="bg-overlay"></div>
          </div>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default Main;
