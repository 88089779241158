import { Button, Form, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import Messages from "../../../../../components/antDesign/Message";
import { importClientSMS, importMislakaSMS, orderMislakaSMS } from "../../../../../services/client";
import PATTERNS from "../../../../../helpers/Patterns";
import moment from "moment";

// Types
interface Props {
  onClose: (clientId?: string | null) => void;
}

interface LoadingState {
  import: boolean;
  orderMislakaOld: boolean;
  downloadMislakaOld: boolean;
  downloadMislakaAndOrder: boolean;
}

interface ModalState {
  isExistingClient: boolean;
  orderMislaka: boolean;
  orderMislakaOld: boolean;
}

interface ResponseData {
  mislakaDate?: string;
  fileId?: string;
}

// Component
const ImportSMSModal: React.FC<Props> = ({ onClose }) => {
  const [form] = Form.useForm();

  // State Management
  const [loading, setLoading] = useState<LoadingState>({
    import: false,
    orderMislakaOld: false,
    downloadMislakaOld: false,
    downloadMislakaAndOrder: false
  });

  const [modalState, setModalState] = useState<ModalState>({
    isExistingClient: false,
    orderMislaka: false,
    orderMislakaOld: false
  });

  const [smsClientId, setSmsClientId] = useState<number>(0);
  const [responseData, setResponseData] = useState<ResponseData | undefined>();
  const [clientIdToUpdate, setClientIdToUpdate] = useState<string | null>(null);

  // Helper Functions
  const updateLoading = (key: keyof LoadingState, value: boolean) => {
    setLoading(prev => ({ ...prev, [key]: value }));
  };

  const updateModalState = (key: keyof ModalState, value: boolean) => {
    setModalState(prev => ({ ...prev, [key]: value }));
  };

  const resetState = () => {
    form.resetFields();
    setModalState({
      isExistingClient: false,
      orderMislaka: false,
      orderMislakaOld: false
    });
    setLoading({
      import: false,
      orderMislakaOld: false,
      downloadMislakaOld: false,
      downloadMislakaAndOrder: false
    });
  };

  // Handlers
  const handleImport = async (values: { clientId: string; updateDetails: boolean }) => {
    updateLoading('import', true);

    try {
      const response = await importClientSMS(values);

      switch (response.status) {
        case 'SUCCESS':
          updateModalState('isExistingClient', true);
          onClose(response.clientId);
          await Messages.Success('ייבוא הלקוח בוצע בהצלחה');
          break;

        case 'EXISTS':
          setClientIdToUpdate(response.clientId);
          updateModalState('isExistingClient', true);
          break;

        case 'MISLAKA_ERROR':
          handleMislakaError(response);
          break;

        case 'ERROR':
          await handleError(response.code);
          break;
      }
    } catch (error) {
      console.error(error);
      await Messages.Error('שגיאה בזמן ייבוא לקוח');
    } finally {
      updateLoading('import', false);
    }
  };

  const handleMislakaError = (response: any) => {
    setClientIdToUpdate(response.clientId);
    setSmsClientId(response.smsClientId);

    switch (response.reason) {
      case 'NOT_FOUND':
        updateModalState('orderMislaka', true);
        break;
      case 'WAITING':
        showWaitingModal(response.clientId);
        break;
      case 'OLD_FOUND':
        updateModalState('orderMislakaOld', true);
        setResponseData(response.data);
        break;
    }
  };

  const handleError = async (code?: number) => {
    const errorMessages: Record<number | string, any> = {
      404: 'תעודת זהות אינה קיימת ב SMS',
      401: {
        content: (
          <>
            התחברות למערכת SMS נכשלה, נא בדוק את פרטי התחברות
            <a href="/profile" className="text-blue-500 hover:underline"> באיזור האישי </a>
          </>
        )
      },
      default: 'ייבוא הלקוח נכשל'
    };

    await Messages.Error(errorMessages[code || 'default']);
  };

  const handleOrderMislaka = async () => {
    try {
      const response = await orderMislakaSMS({ smsClientId });

      if (response.status === 'MISSING-ERROR') {
        showMissingInfoModal(response.list);
        return;
      }

      if (response.status === 'SUCCESS') {
        onClose(clientIdToUpdate);
        await Messages.Success('הוזמנה מסלקה בהצלחה!');
      }
    } catch (error) {
      console.error(error);
      await Messages.Error('שגיאה בהזמנת מסלקה');
    }
  };

  const handleDownloadMislaka = async (shouldOrderNew: boolean = false) => {
    const loadingKey = shouldOrderNew ? 'downloadMislakaAndOrder' : 'downloadMislakaOld';
    updateLoading(loadingKey, true);

    try {
      const response = await importMislakaSMS({
        smsClientId,
        clientId: form.getFieldValue('clientId').toString(),
        fileId: responseData?.fileId
      });

      if (response.status === 'SUCCESS') {
        if (shouldOrderNew) {
          await handleOrderMislaka();
        }
        updateModalState('isExistingClient', true);
        onClose(clientIdToUpdate);
        await Messages.Success('ייבוא הלקוח בוצע בהצלחה');
      }
    } catch (error) {
      console.error(error);
      await Messages.Error('שגיאה בייבוא מסלקה');
    } finally {
      updateLoading(loadingKey, false);
    }
  };

  // Modal Helpers
  const showWaitingModal = (clientId: string) => {
    Modal.info({
      title: 'מצב מסלקה',
      content: (
        <div>
          <p>פרטי העמית/ה עודכנו בהצלחה!</p>
          <p>הזמנת מסלקה לא הושלמה עדיין</p>
        </div>
      ),
      onOk() {
        onClose(clientId);
      },
    });
  };

  const showMissingInfoModal = (missingFields: string[]) => {
    Modal.error({
      title: 'חוסר מידע',
      content: (
        <div>
          <p>על מנת להזמין מסלקה יש להשלים את הפרטים הבאים:</p>
          <ul>
            {missingFields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </div>
      ),
      onOk() {
        onClose(clientIdToUpdate);
      },
    });
  };

  // Render
  return (
    <>
      <Modal
        title="ייבוא לקוח מ - SMS"
        open={true}
        afterClose={() => {
          resetState();
          onClose(undefined);
        }}
        onCancel={() => onClose(undefined)}
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button
            key="import"
            loading={loading.import}
            type="primary"
            onClick={() => form.submit()}
          >
            ייבוא
          </Button>
        ]}
      >
        <Spin spinning={loading.import}>
          <Form form={form} onFinish={handleImport} layout="vertical">
            <Form.Item
              label="תעודת זהות"
              name="clientId"
              rules={[
                { required: true, message: "שדה חובה" },
                { pattern: PATTERNS.NUMBER_ID, message: "מס׳ תעודת זהות לא חוקי" }
              ]}
            >
              <Input placeholder="תעודת זהות" inputMode="numeric" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        open={modalState.isExistingClient}
        title="אישור עדכון פרטי עמית/ה"
        onCancel={() => {
          updateLoading('import', false);
          updateModalState('isExistingClient', false);
        }}
        footer={[
          <Button key="no" onClick={async () => {
            updateModalState('isExistingClient', false);
            await handleImport({ clientId: form.getFieldValue('clientId'), updateDetails: false });
          }}>
            לא
          </Button>,
          <Button key="yes" type="primary" onClick={async () => {
            updateModalState('isExistingClient', false);
            await handleImport({ clientId: form.getFieldValue('clientId'), updateDetails: true });
          }}>
            כן
          </Button>
        ]}
      >
        <p>לקוח זה קיים במערכת, האם לעדכן את פרטי העמית/ה?</p>
      </Modal>

      <Modal
        open={modalState.orderMislaka}
        title="הזמנת מסלקה"
        onCancel={() => onClose(clientIdToUpdate)}
        footer={[
          <Button key="no" onClick={() => {
            updateModalState('orderMislaka', false);
            onClose(clientIdToUpdate);
          }}>
            לא
          </Button>,
          <Button key="yes" type="primary" onClick={async () => {
            updateModalState('orderMislaka', false);
            await handleOrderMislaka();
          }}>
            כן
          </Button>
        ]}
      >
        <p>פרטי העמית/ה עודכנו בהצלחה!</p>
        <p>לא נמצא קובץ מסלקה במערכת SMS, האם תרצה להזמין קובץ?</p>
      </Modal>

      <Modal
        open={modalState.orderMislakaOld}
        title="הזמנת מסלקה"
        onCancel={() => {
          if (!loading.downloadMislakaOld && !loading.orderMislakaOld) {
            updateLoading('import', false);
            updateModalState('isExistingClient', false);
            onClose(clientIdToUpdate);
          }
        }}
        footer={[
          <Button
            key="download"
            loading={loading.downloadMislakaOld}
            disabled={loading.orderMislakaOld || loading.downloadMislakaAndOrder}
            onClick={() => handleDownloadMislaka()}
          >
            הורד נוכחי
          </Button>,
          <Button
            key="downloadAndOrder"
            loading={loading.downloadMislakaAndOrder}
            disabled={loading.orderMislakaOld || loading.downloadMislakaOld}
            onClick={() => handleDownloadMislaka(true)}
          >
            הורד נוכחי והזמן מסלקה
          </Button>,
          <Button
            key="orderNew"
            type="primary"
            disabled={loading.downloadMislakaOld || loading.downloadMislakaAndOrder}
            loading={loading.orderMislakaOld}
            onClick={async () => {
              updateModalState('orderMislaka', false);
              updateLoading('orderMislakaOld', true);
              await handleOrderMislaka();
            }}
          >
            הזמן חדש
          </Button>
        ]}
      >
        <p>פרטי העמית/ה עודכנו בהצלחה!</p>
        <p>
          נמצא קובץ מסלקה מהתאריך {moment(responseData?.mislakaDate).format('DD/MM/YYYY')},
          האם ברצונך לייבא אותו או להזמין חדש?
        </p>
      </Modal>
    </>
  );
};

export default ImportSMSModal;
