export const jsonToFormData = (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  }
  return formData;
}

