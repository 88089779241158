import axios from 'axios';
import baseService from "../base";
import {getUserToken} from "../auth";

const getOptions = (options: AXIOS.OptionsParams) => {
  let optionsSelected: any;
  optionsSelected = baseService.options;
  if (options.auth) {
    optionsSelected.headers.Authorization = options.anotherToken || getUserToken();
  }
  if (options.formData) {
    optionsSelected.headers['Content-Type'] = 'multipart/form-data';
  }
  else{
    optionsSelected.headers['Content-Type'] = 'application/json';
  }

  if(options.params){
    optionsSelected.params = options.params;
  }
  return optionsSelected;
}
const GET = async (url: string, options: AXIOS.OptionsParams = {}) => {
  const optionsSelected = getOptions(options);
  return (await axios.get(url, optionsSelected)).data;
}
const POST = async (url: string, data: any = undefined, options: AXIOS.OptionsParams = {}) => {
  const optionsSelected = getOptions(options);
  return (await axios.post(url, data, optionsSelected)).data;
}
const PUT = async (url: string, data: any = undefined, options: AXIOS.OptionsParams = {}) => {
  const optionsSelected = getOptions(options);
  return (await axios.put(url, data, optionsSelected)).data;
}
const DELETE = async (url: string, data: any = undefined, options: AXIOS.OptionsParams = {}) => {
  let optionsSelected = getOptions(options);
  optionsSelected = {...optionsSelected, data: data};
  return (await axios.delete(url, optionsSelected)).data;
}
// handler all errors.
axios.interceptors.response
  .use((response) => {
    // Do something with response data
    return response;
  }, (err) => {
    // Do something with response error
    throw err.response.data;
  });
export default {GET, POST, PUT, DELETE}
