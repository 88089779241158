import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import heIL from 'antd/lib/locale/he_IL';
import store from './store';
import {ConfigProvider} from 'antd';

const config = {
  token: {
    colorPrimary: '#265867',
    colorLink: '#47a9c7',
    fontFamily: 'Poppins, sans-serif',
    colorTextBase: '#333',
    colorIcon: '#265867',
  },
};

// Create container for the root
const container = document.getElementById('root');
const root = createRoot(container);

// Render app
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider direction="rtl" locale={heIL} theme={config}>
          <App/>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
