import React, { useState } from 'react';
import { Col, Row, Typography, Upload, Space } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import Button from '../../../components/antDesign/Button';
import useDeviceDetect from "../../../hooks/useDeviceDetect";

const { Dragger } = Upload;
const { Title, Text } = Typography;

interface IdentityDocsTabProps {
  onUpdateDocs: (frontFile: File | null, backFile: File | null, attachmentFile: File | null) => Promise<void>;
  onSkip: () => void;
  error?: {
    active: boolean;
    message: string;
  };
}

const IdentityDocsTab: React.FC<IdentityDocsTabProps> = ({ onUpdateDocs, error, onSkip }) => {
  const [frontFile, setFrontFile] = useState<RcFile | null>(null);
  const [backFile, setBackFile] = useState<RcFile | null>(null);
  const [attachmentFile, setAttachmentFile] = useState<RcFile | null>(null);
  const {isMobile} = useDeviceDetect();

  const handleSubmit = async () => {
    await onUpdateDocs(frontFile, backFile, attachmentFile);
  };

  const handleFrontUpload = (info: UploadChangeParam) => {
    const { file } = info;
    setFrontFile(file as RcFile);
  };

  const handleBackUpload = (info: UploadChangeParam) => {
    const { file } = info;
    setBackFile(file as RcFile);
  };

  const handleAttachmentUpload = (info: UploadChangeParam) => {
    const { file } = info;
    setAttachmentFile(file as RcFile);
  };

  const uploadProps = {
    beforeUpload: (file: RcFile) => {
      // Return false to prevent automatic upload
      return false;
    },
    maxCount: 1,
  };

  return (
    <div className="identity-docs-tab">
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Title level={5} className="text-center">העלאת צילומי תעודת זהות</Title>
          <Text className="text-center block mb-4" style={{width: '70%'}}>
            אנא העלה צילום של שני צדדי תעודת הזהות שלך, במידה ואין ברשותך ת.ז. ביומטרי יש לצלם את ת.ז. ולהעלות אותה בצד קדמי
          </Text>
        </Col>

        <Col span={24}>
          <Space direction={isMobile ? "vertical" : "horizontal"}
                 style ={{width: '100%', justifyContent: 'center'}}
                 className="w-full" size="large">
            <div>
              <Text strong className="mb-2 block">צד קדמי של תעודת זהות</Text>
              <Dragger
                {...uploadProps}
                onChange={handleFrontUpload}
                accept="image/*,.pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">לחץ או גרור לכאן קובץ להעלאה</p>
                <p className="ant-upload-hint">ניתן להעלות קובץ PDF או תמונה</p>
              </Dragger>
            </div>

            <div>
              <Text strong className="mb-2 block">צד אחורי של תעודת זהות</Text>
              <Dragger
                {...uploadProps}
                onChange={handleBackUpload}
                accept="image/*,.pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">לחץ או גרור לכאן קובץ להעלאה</p>
                <p className="ant-upload-hint">ניתן להעלות קובץ PDF או תמונה</p>
              </Dragger>
            </div>

            <div>
              <Text strong className="mb-2 block">ספח</Text>
              <Dragger
                {...uploadProps}
                onChange={handleAttachmentUpload}
                accept="image/*,.pdf"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">לחץ או גרור לכאן קובץ להעלאה</p>
                <p className="ant-upload-hint">ניתן להעלות קובץ PDF או תמונה</p>
              </Dragger>
            </div>
          </Space>
        </Col>

        {error?.active && (
          <Col span={24}>
            <Text type="danger">{error.message}</Text>
          </Col>
        )}

        <Col span={24} className="text-center">
          <Button
            type="primary"
            htmlType="submit"
            className='custom-button'
            onClick={handleSubmit}
            >
            המשך
          </Button>
        </Col>

          <Col span={24} className="text-center">
            <Button type="link"
                    style={{paddingRight: 5, color: 'blue'}}
                    onClick={() => onSkip()}>
              המשך ללא העלאת צילום ת.ז.
            </Button>
          </Col>

      </Row>
    </div>
  );
};

export default IdentityDocsTab;
