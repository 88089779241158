import React, {useRef, useState} from 'react';
import {Button, Card, Col, Dropdown, MenuProps, Modal, Row, Space, Tooltip} from "antd";
import {ActionType, ProColumns} from "@ant-design/pro-components";
import {createClientSMS, getClientsList, getTreatmentClientsList, orderMislakaSMS} from "../../../services/client";
import {addressConcatenation} from "../../../helpers/utils/client";
import {
  CloseOutlined,
  CodeSandboxOutlined,
  DeliveredProcedureOutlined,
  EditOutlined,
  EyeOutlined,
  FileOutlined,
  FolderOpenOutlined,
  EllipsisOutlined,
  UploadOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import ViewClientModal from "../ViewClientModal";
import DocumentsSignatureModal from "../DocumentsSignatureModal";
import AddEditClientModal from "../AddEditClientModal";
import {getLoggedInUser, getUserId} from "../../../services/auth";
import moment from "moment";
import InsuranceMountainUploader from "./component/insuranceMountainUploader";
import MislakaUploader from "./component/MislakaUploader";
import ConnectMountainInsurance from "../components/modals/connectMountainInsurance";
import Table from "../../antDesign/ProTable";
import Boxes from "../components/modals/boxes";
import {useHistory} from "react-router-dom";
import ImportSMSModal from "../components/modals/importClient";
import Messages from "../../antDesign/Message";
import CopyToClipboard from "../../CopyToClipboard";
import ExportToExcelButton from "../../ExportToExcel";
import Loader from 'src/components/antDesign/Loader';

const ClientsList: React.FC = () => {
  const [userViewModalShow, setUserViewModalShow] = useState<boolean>(false);
  const [boxesModalShow, setBoxesModalShow] = useState<boolean>(false);
  const [importSMSModalShow, setImportSMSModalShow] = useState<boolean>(false);
  const [addEditClientModalShow, setAddEditClientModalShow] = useState<boolean>(false);
  const [sendDocumentsModalShow, setSendDocumentsModalShow] = useState<boolean>(false);
  const [connectMountainInsuranceModalShow, setConnectMountainInsuranceModalShow] = useState<boolean>(false);
  const [isNew, setNew] = useState<boolean>(false);
  const [uploaderIndexSelected, setUploaderIndexSelected] = useState<number>(0);
  const [clientDataModal, setClientDataModal] = useState<any>({});
  const history = useHistory();
  const actionRef = useRef<ActionType>();
  const [orderMislakaLoading, setOrderMislakaLoading] = useState<boolean>(false);
  const [isSMSExistingClient, setIsSMSExistingClient] = useState<boolean>(false);
  const [isSMSExistingClientId, setIsSMSExistingClientId] = useState<string>('');
  const [isForceOrderModal, setIsForceOrderModal] = useState<boolean>(false);
  const [forceOrderData, setForceOrderData] = useState<any>({});

  const handleCreateClintSMS = (clientId: string, forceUpdate: boolean | undefined) => {
    setOrderMislakaLoading(true);
    createClientSMS({clientId: clientId, forceUpdate: forceUpdate}).then((response) => {
      if (response.status === 'ERROR') {
        switch (response.code) {
          case 401:
            Messages.Error({
              content: (
                <>
                  התחברות למערכת SMS נכשלה, נא בדוק את פרטי התחברות
                  <a href="/profile" className="text-blue-500 hover:underline"> באיזור האישי </a>
                </>
              )
            }).then(() => {
            });
            setIsSMSExistingClientId('');
            break;
          case 409:
            setIsSMSExistingClient(true);
            setIsSMSExistingClientId(clientId);
            break
          default:
            Messages.Error('יצירת לקוח ב SMS נכשלה').then(() => {
            });
            setIsSMSExistingClientId('');
        }

      }

      if (response.status === 'SUCCESS') {
        Messages.Success('עמית הוקם במערכת SMS בהצלחה!').then(() => {
        });
        setIsSMSExistingClientId('');
      }
      setOrderMislakaLoading(false);
    });
  }

  const handleOrderMislaka = (data: any) => {
    setOrderMislakaLoading(true);
    orderMislakaSMS(data).then((response) => {
      if (response.status === 'MISSING-ERROR') {
        Modal.error({
          title: 'חוסר מידע',
          content: (
            <div>
              <p>על מנת להזמין מסלקה יש להשלים את הפרטים הבאים:</p>
              <ul>
                {response.list.map((data: String) => <li>{data}</li>)}
              </ul>
            </div>)
        });
      } else if (response.status === 'ERROR') {
        switch (response.code) {
          case 401:
            Messages.Error({
              content: (
                <>
                  התחברות למערכת SMS נכשלה, נא בדוק את פרטי התחברות
                  <a href="/profile" className="text-blue-500 hover:underline"> באיזור האישי </a>
                </>
              )
            }).then(() => {
            });
            break;
          case 404:
            Messages.Error('עמית זה אינו קיים במערכת SMS, יש ליצור עמית זה תחילה').then(() => {
            });
            break;
          case 409:
            setForceOrderData(data);
            setIsForceOrderModal(true);
            break;
          default:
            Messages.Error('הזמנת קובץ מסלקה נכשל').then(() => {
            });
        }
      }

      if (response.status === 'SUCCESS') {
        Messages.Success('קובץ מסלקה הוזמן בהצלחה!').then(() => {
        })
      }
      setOrderMislakaLoading(false);
    });
  }
  const columnsAgent: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.clientId ? a.clientId.localeCompare(b.clientId) : false,
      title: "תעודת זהות",
      dataIndex: 'clientId',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (text: any, record: any) => (
        <CopyToClipboard linkUrl={`/client-details?cid=${record._id}`} text={text} markHasLink/>
      ),
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.fullName ? a.fullName.localeCompare(b.fullName) : false,
      title: "שם מלא",
      dataIndex: 'fullName',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => (
        <CopyToClipboard linkUrl={`/client-details?cid=${record._id}`} text={record.fullName}/>
      )
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.phone ? a.phone.localeCompare(b.phone) : false,
      title: "טלפון",
      dataIndex: "phone",
      fieldProps: {
        placeholder: 'טלפון'
      },
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => (
        <CopyToClipboard linkUrl={`/client-details?cid=${record._id}`} text={record.phone}/>
      )
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.email ? a.email.localeCompare(b.email) : false,
      title: "אימייל",
      dataIndex: "email",
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => (
        <CopyToClipboard linkUrl={`/client-details?cid=${record._id}`} text={record.email}/>
      )
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.fullAddress ? a.fullAddress.localeCompare(b.fullAddress) : false,
      title: "כתובת",
      dataIndex: "fullAddress",
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => (
        <CopyToClipboard linkUrl={`/client-details?cid=${record._id}`} text={record.fullAddress}/>
      )
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.familyStatus ? a.familyStatus.localeCompare(b.familyStatus) : false,
      title: "מצב משפחתי",
      dataIndex: 'familyStatus',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.age ? a.age.localeCompare(b.age) : false,
      title: "גיל",
      dataIndex: 'age',
    }, {
      align: 'center',
      title: "",
      dataIndex: 'actions',
      width: 140,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => [
        <div style={{display: "flex", justifyContent: "center"}}>
          <Space size={0}>
            <Tooltip title='צפייה פרטי לקוח'>
              <Button
                style={{padding: '0 4px'}}
                type='link'
                size={"large"}
                onClick={() => {
                  setUserViewModalShow(true);
                  setClientDataModal(record);
                }}
              >
                <EyeOutlined/>
              </Button>
            </Tooltip>
            <Tooltip title='עריכת פרטי לקוח'>
              <Button
                style={{padding: '0 4px'}}
                type='link'
                size={"large"}
                onClick={() => {
                  setAddEditClientModalShow(true);
                  setNew(false);
                  setClientDataModal(record);
                }}
              >
                <EditOutlined/>
              </Button>
            </Tooltip>
            <Tooltip title='הזמן מסלקה'>
              <Button
                style={{padding: '0 4px'}}
                type='link'
                size={"large"}
                onClick={() => {
                  handleOrderMislaka({smsClientId: record.smsClientId, clientId: record._id, forceOrder: false})
                }}
              >
                <DeliveredProcedureOutlined/>
              </Button>
            </Tooltip>
            <Tooltip title='צור עמית במערכת SMS'>
              <Button
                style={{padding: '0 4px'}}
                type='link'
                size={"large"}
                onClick={() => {
                  handleCreateClintSMS(record._id, undefined)
                }}
              >
                <UsergroupAddOutlined/>
              </Button>
            </Tooltip>
            <div key={record._id}>
              <Dropdown arrow placement='bottomRight' menu={{
                items: [
                  {
                    key: '1',
                    label: (
                      <p
                        style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                        onClick={() => {
                          history.push('/client-details?cid=' + record._id);
                        }}>
                        <Space>
                          <FolderOpenOutlined/>
                          תיק לקוח
                        </Space>
                      </p>
                    ),
                  },
                  /*{
                    key: '2',
                    label: (
                      <p
                        style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                        onClick={() => {
                          setUserViewModalShow(true);
                          setClientDataModal(record)
                        }}>
                        <Space>
                          <EyeOutlined/>
                          צפייה
                        </Space>
                      </p>
                    ),
                  },
                  {
                    key: '3',
                    label: (
                      <p
                        style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                        onClick={() => {
                          setAddEditClientModalShow(true);
                          setNew(false);
                          setClientDataModal(record);
                        }}>
                        <Space>
                          <EditOutlined/>
                          עריכה
                        </Space>
                      </p>
                    ),
                  },*/
                  {
                    key: '4',
                    label: (
                      <p
                        style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                        onClick={() => {
                          setSendDocumentsModalShow(true);
                          setClientDataModal(record);
                        }}>
                        <Space>
                          <FileOutlined/>
                          חתימת מסמכים
                        </Space>
                      </p>
                    ),
                  },
                  {
                    key: '5',
                    label: (
                      <p
                        style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                        onClick={() => {
                          setBoxesModalShow(true);
                          setClientDataModal(record);
                        }}>
                        <Space>
                          <CodeSandboxOutlined/>
                          צור קובץ קוביות (ה׳1)
                        </Space>
                      </p>
                    ),
                  },
                  /*{
                    key: '6',
                    label: (
                      <p
                        style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                        onClick={() => {
                          setConnectMountainInsuranceModalShow(true);
                          setClientDataModal(record);
                        }}>
                        <Space>
                          <DownloadOutlined/>
                          קבלת מידע ביטוחי - הר הביטוח
                        </Space>
                      </p>
                    ),
                  },
                  {
                    key: '7',
                    label: (
                      <p
                        style={{cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
                        onClick={() => {
                          orderMislakaSMS({smsClientId: record.smsClientId}).then((response) => {
                            if (response.status === 'MISSING-ERROR') {
                              Modal.error({
                                title: 'חוסר מידע',
                                content: (
                                  <div>
                                    <p>על מנת להזמין מסלקה יש להשלים את הפרטים הבאים:</p>
                                    <ul>
                                      {response.list.map((data: String) => <li>{data}</li>)}
                                    </ul>
                                  </div>)
                              });
                            } else if (response.status === 'ERROR') {
                              switch (response.code) {
                                case 401:
                                  Messages.Error({
                                    content: (
                                      <>
                                        התחברות למערכת SMS נכשלה, נא בדוק את פרטי התחברות
                                        <a href="/profile" className="text-blue-500 hover:underline"> באיזור האישי </a>
                                      </>
                                    )
                                  }).then(() => {
                                  });
                                  break;
                                default:
                                  Messages.Error('הזמנת קובץ מסלקה נכשל').then(() => {
                                  });
                              }
                            }

                            if (response.status === 'SUCCESS') {
                              Messages.Success('קובץ מסלקה הוזמן בהצלחה!').then(() => {
                              })
                            }
                          });
                        }}>
                        <Space>
                          <DeliveredProcedureOutlined/>
                          הזמן מסלקה
                        </Space>
                      </p>
                    ),
                  },*/
                ]
              }} trigger={['click']}>
                <Tooltip title='עוד'>
                  <Button
                    style={{padding: '0 4px'}}
                    type='link'
                    size={"large"}
                  >
                    <EllipsisOutlined/>
                  </Button>
                </Tooltip>
              </Dropdown>
            </div>
          </Space>
        </div>
      ]
    }
  ];

  const columnsTreatment: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.clientId ? a.clientId.localeCompare(b.clientId) : false,
      title: "תעודת זהות",
      fieldProps: {
        placeholder: 'תעודת זהות'
      },
      dataIndex: 'clientId',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.firstName ? a.firstName.localeCompare(b.firstName) : false,
      title: "שם פרטי",
      fieldProps: {
        placeholder: 'שם פרטי'
      },
      dataIndex: 'firstName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.lastName ? a.lastName.localeCompare(b.lastName) : false,
      title: "שם משפחה",
      fieldProps: {
        placeholder: 'שם משפחה'
      },
      dataIndex: 'lastName',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.phone ? a.phone.localeCompare(b.phone) : false,
      title: "טלפון",
      fieldProps: {
        placeholder: 'טלפון'
      },
      dataIndex: 'phone',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.note ? a.note.localeCompare(b.note) : false,
      title: "הערות",
      fieldProps: {
        placeholder: 'הערות'
      },
      dataIndex: 'note',
    }, {
      align: 'right',
      sorter: (a: any, b: any) => a.agent_name ? a.agent_name.localeCompare(b.agent_name) : false,
      title: "סוכן",
      fieldProps: {
        placeholder: 'סוכן'
      },
      dataIndex: 'agent_name',
    }, {
      align: 'right',
      title: "פעולות",
      dataIndex: 'actions',
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Tooltip title='עריכה'>
            <Button
              type='link'
              onClick={() => {
                setAddEditClientModalShow(true);
                setNew(false);
                setClientDataModal(record);
              }}>
              <EditOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='צפייה'>
            <Button
              type='link'
              onClick={() => {
                setUserViewModalShow(true);
                setClientDataModal(record)
              }}>
              <EyeOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='תיק לקוח'>
            <Button
              type='link'
              onClick={() => {
                history.push('/client-details?cid=' + record._id);
              }}>
              <FolderOpenOutlined/>
            </Button>
          </Tooltip>

          <Tooltip title='חתימת מסמכים'>
            <Button
              type='link'
              onClick={() => {
                setSendDocumentsModalShow(true);
                setClientDataModal(record);
              }}>
              <FileOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const loadDataTable = async (params: any) => {
    if (getLoggedInUser().type === 'treatment') {
      return await getTreatmentClientsList(params).then((response) => {
        const dataTable = response.list.map((user: any) => {
          return {
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            agent: user.agent,
            agent_name: user.agent.firstName + " " + user.agent.lastName,
            telephone: user.telephone,
            products: user.products,
            postalCode: user.postalCode,
            note: user.note,
            houseNumber: user.houseNumber,
            country: user.country,
            clientId: user.clientId,
            city: user.city,
            issueIdDate: user.issueIdDate ? moment(user.issueIdDate) : '',
            dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth) : '',
            appendixDocuments: user.appendixDocuments,
            createdBy: user.createdBy,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
          }
        })
        return {
          status: response.status,
          current: response.current,
          total: response.total,
          pageSize: response.pageSize,
          data: dataTable,
        };
      })
    } else {
      return await getClientsList(params).then((response) => {

        const dataTable = response.list.map((user: any) => {
          return {
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user.firstName} ${user.lastName}`,
            email: user.email,
            phone: user.phone,
            telephone: user.telephone,
            products: user.products,
            postalCode: user.postalCode,
            note: user.note,
            houseNumber: user.houseNumber,
            apartmentNumber: user.apartmentNumber,
            country: user.country,
            familyStatus: user.familyStatus.value === 0 ? '' : user.familyStatus.text,
            clientId: user.clientId,
            smsClientId: user.smsClientId,
            city: user.city,
            street: user.street,
            issueIdDate: user.issueIdDate ? moment(user.issueIdDate) : '',
            dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth) : '',
            age: user.age,
            appendixDocuments: user.appendixDocuments,
            createdBy: user.createdBy,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
            fullAddress: addressConcatenation(user.city, user.street, user.houseNumber, user.apartmentNumber)
          }
        })
        return {
          status: response.status,
          current: response.current,
          total: response.total,
          pageSize: response.pageSize,
          data: dataTable,
        };
      })
    }
  }

  const closeViewUserModal = () => {
    setUserViewModalShow(false);
    setNew(false);
  }

  const closeAddEditClientModal = (refresh: boolean) => {
    if (refresh) {
      actionRef.current?.reload();
    }

    setAddEditClientModalShow(false);
    setNew(false);
  }

  const itemsUpload: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <p
          style={uploaderIndexSelected === 1 ?
            {cursor: "pointer", color: "#10bb69", marginBottom: 0, paddingBottom: 5} :
            {cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
          onClick={() => {
            //Messages.Success('בקרוב!!!!')
            setUploaderIndexSelected(1)
          }}>
          קובץ מסלקה
        </p>
      ),
    },
    {
      key: '2',
      label: (
        <p
          style={uploaderIndexSelected === 2 ?
            {cursor: "pointer", color: "#10bb69", marginBottom: 0, paddingBottom: 5} :
            {cursor: "pointer", marginBottom: 0, paddingBottom: 5}}
          onClick={() => setUploaderIndexSelected(2)}>
          קובץ הר הביטוח
        </p>
      ),
    },
  ];

  return (
    <Loader.LoadingContainer loading={orderMislakaLoading} tip='בתהליך ...'>
      <React.Fragment>
        <div className="page-content">

          {/*   <Breadcrumbs title={BreadcrumbsItems.title}/>*/}

          <Row>
            <Col xs={24}>
              <Card>
                <Space
                  style={{width: '100%', justifyContent: 'space-between'}}
                  align="center"
                >
                  {/* Right Side */}
                  <Space>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        setAddEditClientModalShow(true);
                        setNew(true);
                        setClientDataModal({});
                      }}
                    >
                      <i className="mdi mdi-plus mr-2"/>
                      יצירת לקוח
                    </Button>

                    <Button
                      type="primary"
                      size="large"
                      className="blue-btn"
                      onClick={() => {
                        setImportSMSModalShow(true);
                      }}
                    >
                      <i className="mdi mdi-plus mr-2"/>
                      ייבוא מ- SMS
                    </Button>

                    <Dropdown
                      menu={{items: itemsUpload}}
                      trigger={['click']}
                      placement="bottom"
                      className="orange-btn"
                      arrow
                    >
                      <Button type="primary" size="large" icon={<UploadOutlined/>}>
                        טעינת קובץ
                      </Button>
                    </Dropdown>
                  </Space>
                </Space>
              </Card>

            </Col>
          </Row>

          {(uploaderIndexSelected === 1 || uploaderIndexSelected === 2) &&
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card>
                  <Button type='link' size='large' icon={<CloseOutlined/>} onClick={() => setUploaderIndexSelected(0)}/>
                  {uploaderIndexSelected === 1 &&
                    <MislakaUploader
                      accept='.zip'
                      maxCount={1}
                      onUploaded={(clientId: string) => {
                        if (clientId) {
                          history.push(`/client-details?cid=${clientId}`);
                        }
                      }}/>
                  }

                  {uploaderIndexSelected === 2 &&
                    <InsuranceMountainUploader
                      accept='.xlsx'
                      maxCount={1}
                      onUploaded={() => {
                        setUploaderIndexSelected(0);
                        actionRef.current?.reload()
                      }}/>
                  }
                </Card>
              </Col>
            </Row>
          }

          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card title="רשימת לקוחות"
                    bordered={false}
                    extra={
                      <ExportToExcelButton
                        loadDataTable={loadDataTable}
                        fileName="לקוחות"
                        columns={columnsAgent.filter((col) => col.dataIndex !== 'actions')}
                      />
                    }
              >
                <Table
                  style={{cursor: 'pointer'}}
                  handleRowClick={(record) => history.push(`/client-details?cid=${record._id}`)}
                  actionRef={actionRef}
                  request={loadDataTable}
                  columns={getLoggedInUser().type === 'treatment' ? columnsTreatment : columnsAgent}
                  showPagination
                />
              </Card>
            </Col>
          </Row>

          <ViewClientModal visible={userViewModalShow} clientDataModal={clientDataModal}
                           onClose={() => closeViewUserModal()}/>

          <AddEditClientModal visible={addEditClientModalShow}
                              clientDataModal={clientDataModal} isNew={isNew}
                              onClose={(refresh: boolean) => closeAddEditClientModal(refresh)}/>
          <DocumentsSignatureModal
            client={clientDataModal}
            visible={sendDocumentsModalShow}
            onClose={() => setSendDocumentsModalShow(false)}/>

          <ConnectMountainInsurance
            client={{
              firstName: clientDataModal?.firstName || '',
              lastName: clientDataModal?.lastName || '',
            }}
            userId={getUserId()}
            visible={connectMountainInsuranceModalShow}
            onClose={() => {
              setConnectMountainInsuranceModalShow(false)
            }}/>

          <Boxes
            visible={boxesModalShow}
            client={clientDataModal}
            onClose={() => {
              setBoxesModalShow(false)
            }}
            onOk={() => {
              setBoxesModalShow(false)
            }}/>

          {importSMSModalShow && <ImportSMSModal onClose={(clientId) => {
            if (clientId) {
              history.push(`/client-details?cid=${clientId}`);
            }
            setImportSMSModalShow(false);
          }}/>}

          <Modal
            open={isSMSExistingClient}
            title="אישור עדכון פרטי עמית/ה"
            onCancel={() => {
              setIsSMSExistingClient(false);
              setIsSMSExistingClientId('');
            }}
            footer={[
              <Button key="no" onClick={async () => {
                setOrderMislakaLoading(true);
                setIsSMSExistingClient(false);
                handleCreateClintSMS(isSMSExistingClientId, false);
              }}>
                לא
              </Button>,
              <Button key="yes" type="primary" onClick={async () => {
                setOrderMislakaLoading(true);
                setIsSMSExistingClient(false);
                handleCreateClintSMS(isSMSExistingClientId, true);
              }}>
                כן
              </Button>
            ]}
          >
            <p>עמית זה קיים במערכת SMS, האם לעדכן את פרטי העמית/ה?</p>
          </Modal>

          <Modal
            open={isForceOrderModal}
            title="אישור הזמנת מסלקה"
            onCancel={() => {
              setOrderMislakaLoading(false);
              setIsForceOrderModal(false);
            }}
            footer={[
              <Button key="no" onClick={async () => {
                setOrderMislakaLoading(false);
                setIsForceOrderModal(false);
              }}>
                לא
              </Button>,
              <Button key="yes" type="primary" onClick={async () => {
                setIsForceOrderModal(false);
                handleOrderMislaka({...forceOrderData, forceOrder: true});
              }}>
                כן
              </Button>
            ]}
          >
            <p>הוזמן לעמית זה מסלקה ב 3 חודשים האחרונים, האם להזמין בכל זאת?</p>
          </Modal>
        </div>
      </React.Fragment>
    </Loader.LoadingContainer>
  );
};

export default ClientsList;
