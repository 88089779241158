import React from 'react';
import {Button} from 'antd';
import {Workbook} from 'exceljs';
import {saveAs} from 'file-saver';
import {RiFileExcel2Line} from "react-icons/ri";

interface ExportToExcelButtonProps {
  loadDataTable: (params?: any) => Promise<{ data: any[] }>;
  fileName?: string;
  columns?: any[];
}

const ExportToExcelButton: React.FC<ExportToExcelButtonProps> = ({
                                                                   loadDataTable,
                                                                   fileName = 'Export',
                                                                   columns,
                                                                 }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleExport = async () => {
    try {
      setIsLoading(true);

      // Fetch data
      const {data} = await loadDataTable({});
      if (!data || data.length === 0) {
        console.warn('No data available for export');
        setIsLoading(false);
        return;
      }

      // Create a new workbook and worksheet
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');

      // Prepare columns and headers
      const excelColumns = columns?.map(col => ({
        header: col.title,
        key: col.dataIndex,
        width: Math.max(col.title.length, 15), // Set a default width
      })) || Object.keys(data[0] || {}).map(key => ({
        header: key,
        key,
        width: key.length,
      }));

      worksheet.columns = excelColumns;

      // Apply header styles
      worksheet.getRow(1).eachCell(cell => {
        cell.font = {size: 12, bold: true, color: {argb: 'FFFFFFFF'}}; // White text
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FF0a9d55'}, // Green background
        };
        cell.alignment = {horizontal: 'center', vertical: 'middle'};
      });

      // Add data rows
      data.forEach(item => {
        const row = columns
          ? columns.reduce((acc, col) => {
            acc[col.dataIndex] = item[col.dataIndex];
            return acc;
          }, {})
          : item;
        worksheet.addRow(row);
      });

      // Adjust column widths dynamically
      worksheet.columns.forEach(column => {
        column.width = Math.max(
          column.header?.length || 10,
          ...data.map(item => String(item[column.key as string] || '').length)
        );
      });

      // Generate buffer and save file
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Export to Excel failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="default"
      size="middle"
      icon={<RiFileExcel2Line/>}
      onClick={handleExport}
      loading={isLoading}
      title="Export data to Excel"
    />
  );
};

export default ExportToExcelButton;
