import React, {useEffect, useState} from 'react';
import {Col, Form, Modal, Row, Select} from 'antd';
import {Editor} from 'react-draft-wysiwyg';
import {useTaskForm} from '../../hooks/useTaskForm';
import {DynamicField} from './DynamicField';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loader from '../../../antDesign/Loader';
import {getUsersByType} from "../../../../services/user";
import {getLoggedInUser, getLoggedInUserId} from "../../../../services/auth";
import {getClientsList, getClientWithoutSignature} from "../../../../services/client";
import {convertFromRaw, EditorState} from "draft-js";
import {getTaskProperties} from "../../../../services/task";
import moment from "moment/moment";
import {statuses} from "../../../../pages/Tasks/statuses";
import {manufacturersOptions} from "../../../../services/manufacturer";

const TaskFormModal: React.FC<TASKS.TaskModalProps> = ({
                                                         visible,
                                                         isNew,
                                                         task,
                                                         onOk,
                                                         onCancel,
                                                         afterClose,
                                                         clientId,
                                                         isSelected
                                                       }) => {
  const {
    form,
    loading,
    loadingMsg,
    editorState,
    setEditorState,
    dynamicFields,
    setDynamicFields,
    handleSubmit,
    setProperty
  } = useTaskForm(isNew, task, onOk);

  //const [clientId, setClientId] = useState<string | undefined>(clientId);
  const [client, setClient] = useState<any | undefined>(undefined);

  // select
  const [selectOptionAgents, setSelectOptionAgents] = useState<any>([]);
  const [selectOptionTreatments, setSelectOptionTreatments] = useState<any>([]);
  const [selectOptionTasks, setSelectOptionTasks] = useState<any>([]);
  const [selectOptionSubTasks, setSelectOptionSubTasks] = useState<any>([]);
  const [selectOptionProducts, setSelectOptionProducts] = useState<any>([]);
  const [selectOptionPolicies, setSelectOptionPolicies] = useState<any>([]);
  const [selectOptionClients, setSelectOptionClients] = useState<any>([]);
  const [selectOptionsManufacturers, setSelectOptionsManufacturers] = useState<any>([]);

  const getAgents = () => {
    getUsersByType({type: 'agent'}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        createTemplateAgentsOptions(response.users)
        let user = [];
        if (isNew) {
          if (getLoggedInUser().type === 'agent') {
            user = response.users.filter((u: any) => u._id.toString() === getLoggedInUserId());
          }
        } else
          user = response.users.filter((u: any) => u._id === task.agent_id);
        if (user.length > 0) {
          form.setFieldsValue({agentId: user[0]._id})
        }
      }
    })
  }

  const getTreatments = () => {
    getUsersByType({type: 'treatment'}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        createTemplateTreatmentOptions(response.users)
        let user = [];

        if (isNew) {
          if (getLoggedInUser().type === 'treatment')
            user = response.users.filter((u: any) => u._id.toString() === getLoggedInUserId());
          else {
            if (response.users.length === 1) {
              user.push(response.users[0]);
            }
          }
        } else
          user = response.users.filter((u: any) => u._id === task.treatment_id);

        if (user.length > 0) {
          form.setFieldsValue({treatment: user[0]._id})
        }
      }
    })
  }

  const getClients = () => {
    getClientsList().then((response: any) => {
      createTemplateClientsOptions(response.list);
    })
  }

  const getManufacturers = () => {
    manufacturersOptions().then((response: any) => {
      debugger
      setSelectOptionsManufacturers(response.options)
    })
  }

  const getClient = () => {
    getClientWithoutSignature({clientId: clientId || ''}).then((response: any) => {
      setClient(response.client)
    })
  }

  const createTemplate = () => {
    createTemplateProductsOptions(task, isNew);

    if (!isNew) {
      createTemplatePoliciesOptions(task.product);

      getTaskType().then((properties: any) => {
        createTemplateTasksOptions(properties)
        getTaskType({type: task.properties.type}).then((properties: any) => {
          createTemplateSubTasksOptions(properties)
          displayProperties();
          // setLoading(false)
        })
      })

      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(task.body))));
      form.setFieldsValue({status: task.status.value});
    } else {
      if (isSelected)
        createTemplatePoliciesOptions(task.policy)

      getTaskType().then((properties: any) => {
        createTemplateTasksOptions(properties)
      });

      setEditorState(EditorState.createEmpty())
      // setLoading(false)
    }
  }
  const createTemplateAgentsOptions = (agents: any) => {
    if (agents.length > 0) {
      const options: any = []
      agents.forEach((agent: any) => {
        options.push({
          label: agent.firstName + ' ' + agent.lastName,
          value: agent._id
        })
      })
      setSelectOptionAgents(options)
    }
  }
  const createTemplateTreatmentOptions = (treatments: any) => {
    if (treatments.length > 0) {
      const options: any = []
      treatments.forEach((treatment: any) => {
        options.push({
          label: treatment.firstName + ' ' + treatment.lastName,
          value: treatment._id
        })
      })
      setSelectOptionTreatments(options)
    }
  }
  const createTemplateClientsOptions = (clients: any) => {
    if (clients.length > 0) {
      const options: any = []
      clients.forEach((client: any) => {
        options.push({
          label: client.firstName + ' ' + client.lastName + ' - ' + client.clientId,
          value: client._id,
          client: client
        })
      })
      setSelectOptionClients(options)
    }
  }
  const createTemplateProductsOptions = (products: any, isNew: boolean) => {
    const options = []
    if (isNew && !isSelected && products.length > 0) {
      products.forEach((product: any) => {
        options.push({
          label: product.productType.name,
          value: product.productType.id,
        })
      })
    } else if (products.productType && products.productType.name && products.productType.id) {
      options.push({
        label: products.productType.name,
        value: products.productType.id,
      })
      form.setFieldsValue({product: products.productType.id})
    }
    setSelectOptionProducts(options)
  }
  const createTemplatePoliciesOptions = (policies: any) => {
    const options = []
    if (isNew && !isSelected && policies.length > 0) {
      policies.forEach((policy: any) => {
        options.push({
          label: policy.productName + " - " + policy.policyNumberOrAccountNumber,
          value: policy.policyNumberOrAccountNumber,
        })
      })
    } else if (policies && policies.policyNumberOrAccountNumber && policies.productName) {
      options.push({
        label: policies.productName + " - " + policies.policyNumberOrAccountNumber,
        value: policies.policyNumberOrAccountNumber,
      })
      form.setFieldsValue({policy: policies.policyNumberOrAccountNumber})
    }
    setSelectOptionPolicies(options)
  }
  const createTemplateSubTasksOptions = (properties: any) => {
    const options: any = []
    if (properties.length > 0) {
      properties.forEach((property: any) => {
        if (property.subtype && property.active)
          options.push({
            id: property._id,
            label: property.subtype_label,
            value: property.subtype
          })
      })
      if (options.length === 1)
        form.setFieldsValue({subtask: options.subtype})
    }
    setSelectOptionSubTasks(options);

    // only type without subtype
    if (properties.length === 1 && !properties[0].subtype) {
      setProperty(properties[0]._id)
    }
  }

  const getTaskType = async (data: any | undefined = undefined) => {
    return await getTaskProperties(data).then(response => {
      if (response.status.toString() === 'SUCCESS')
        return response.properties;
      return [];
    })
  }

  const createTemplateTasksOptions = (properties: any) => {
    if (properties.length > 0) {
      const options: any = []
      properties.forEach((property: any) => {
        options.push({
          id: property._id,
          label: property.type_label,
          value: property.type
        })
      })
      setSelectOptionTasks(options)
    }
  }
  const displayProperties = () => {

    let dynamic_fields: any = {};
    let fields = task.properties && task.properties.fields ?
      task.properties.fields.filter((field: any) => field.active) : [];

    if (fields.length > 0) {
      setDynamicFields(fields)
      fields.forEach((field: any) => {
        if (field.type === 'date') {
          const fieldSelected = task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name)[0];
          if (fieldSelected)
            dynamic_fields[fieldSelected['name']] = moment(fieldSelected.value);
        } else if (field.type === 'select') {
          const optionSelected = task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name);
          if (optionSelected.length > 0) {
            const item = field.items.filter((item: any) => item.id === optionSelected[0].value) // get full item for label
            if (item.length > 0) {
              dynamic_fields[field.name] = item[0].id;
            }
          }
        } else if (field.type === 'file') {
          const file = task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name);
          if (file.length > 0) {
            // dynamic_fields[field.name] = JSON.parse(file[0].fileContent);
          }
        } else if (field.type === 'checkbox') {
          const fieldSelected = task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name);

          if (fieldSelected.length > 0) {
            dynamic_fields[fieldSelected[0]['name']] = fieldSelected[0].value === 'true';
          }

        } else {
          // if is regular fields
          const fieldSelected = task.fields_values.filter((fieldValues: any) => fieldValues.name === field.name)[0];
          if (fieldSelected)
            dynamic_fields[fieldSelected['name']] = fieldSelected.value;
        }
      })
    }

    form.setFieldsValue({
      task: task.properties.type,
      subtask: task.properties.subtype,
      treatment: task.treatment_id,
      dynamic_fields
    })
    setProperty(task.properties_id)
  }
  const getExistingFile = (fieldName: string) => {
    if (!task?.fields_values) return undefined;

    const fileField = task.fields_values.find((field: any) => field.name === fieldName);
    if (!fileField) return undefined;

    return {
      name: fileField.fileName || 'Unnamed File',
      contentType: fileField.contentType,
      fileData: fileField.fileContent
    };
  };
  const handleSelectGroupClient = (value: string) => {
    const client = selectOptionClients.filter((c: any) => c.value === value)[0].client;
    setClient(client)
    if (client.products.length > 0)
      createTemplateProductsOptions(client.products, true);
    else
      setSelectOptionProducts([]);
  }

  const loadData = () => {
    getManufacturers();
    getAgents();
    getTreatments();
    if (!clientId)
      getClients();
    else {
      getClient();
      // setClientId(clientId);
    }
    createTemplate();
  }
  const handleSelectGroupTask = (value: string) => {
    getTaskType({type: value}).then((properties: any) => {

      // check if subtype exist
      if (properties.length > 0 && properties[0].subtype) {
        createTemplateSubTasksOptions(properties);
      } else {
        // if subtype not exist - get dynamic fields of type
        if (properties.length > 0) {
          setDynamicFields(properties[0].fields.filter((property: any) => property.active));
          setProperty(properties[0]._id);
        }
      }
    })
  }

  const handleSelectGroupSubTask = (value: string) => {
    getTaskType({
      type: form.getFieldValue('task'),
      subtype: value
    }).then(properties => {
      setDynamicFields(properties.fields.filter((property: any) => property.active))
      setProperty(properties._id)
    })
  }
  const handleSelectGroupProducts = (value: string) => {
    const product = clientId ?
      task.filter((product: any) => value === product.productType.id)[0] :
      client?.products.filter((product: any) => value === product.productType.id)[0];

    createTemplatePoliciesOptions(product.policies);
  }

  const onOpened = () => {
    loadData();
  }

  useEffect(() => {
    if (visible) {
      onOpened();
    }
  }, [visible]);

  // reset all values
  const resetValues = () => {
    // setClientId(undefined);
    setClient(undefined);
    setSelectOptionAgents([])
    setSelectOptionTreatments([])
    setSelectOptionTasks([])
    setSelectOptionSubTasks([])
    setSelectOptionProducts([])
    setSelectOptionPolicies([])
    setSelectOptionClients([])
    setDynamicFields([]);
    setEditorState(EditorState.createEmpty());
    form.resetFields();
  }

  return (
    <Modal
      title={isNew ? "יצירת משימה" : "עדכון משימה"}
      open={visible}
      afterClose={() => {
        resetValues();
        if (afterClose) {
          afterClose();
        }
      }}
      onCancel={onCancel}
      onOk={form.submit}
      maskClosable={false}
      destroyOnClose
      okText={isNew ? "צור" : "עדכן"}
      cancelText="ביטול"
      okButtonProps={{disabled: loading}}
      cancelButtonProps={{disabled: loading}}
      width={1000}>

      <Loader.LoadingContainer loading={loading} tip={loadingMsg}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onValuesChange={(values: any) => {
            if (values.client) {
              form.resetFields(["product", "policy"]);
              handleSelectGroupClient(values.client)
            }

            if (values.task) {
              form.resetFields(["dynamic_fields", "subtask"]);
              setDynamicFields([]);
              setSelectOptionSubTasks([])
              handleSelectGroupTask(values.task)
            }

            if (values.subtask) {
              handleSelectGroupSubTask(values.subtask)
              form.resetFields(["dynamic_fields"]);
            }

            if (values.product) {
              form.resetFields(["policy"]);
              handleSelectGroupProducts(values.product)
            }
          }}>

          <Row gutter={[16, 16]}>
            {/*   <Col sm={8}>
              <Form.Item name="agentId"
                         label='סוכן'
                         rules={!isNew || getLoggedInUser().type === 'agent' ? [] : [{
                           required: true,
                           message: 'שדה חובה'
                         }]}>
                <Select disabled={!isNew || getLoggedInUser().type === 'agent'}>
                  {selectOptionAgents && selectOptionAgents.length > 0 && selectOptionAgents.map((agent: any, index: any) => (
                      <Select.Option key={index} value={agent.value}>{agent.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>*/}

            {selectOptionTreatments && selectOptionTreatments.length > 0 &&
              <Col sm={8}>
                <Form.Item name="treatment"
                           label='גורם מטפל'
                           rules={[{required: true, message: 'שדה חובה'}]}>
                  <Select>
                    {
                      selectOptionTreatments && selectOptionTreatments.length > 0 && selectOptionTreatments.map((treatment: any, index: any) => (
                        <Select.Option key={index}
                                       value={treatment.value}>{treatment.label}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
            }

            {!clientId &&
              <Col sm={8}>
                <Form.Item
                  name="client"
                  label='עמית'
                  rules={[{required: true, message: 'שדה חובה'}]}>
                  <Select
                    showSearch
                    placeholder={'בחר עמית'}
                    optionFilterProp={"label"}
                    options={selectOptionClients.map((client: any) => {
                      return {value: client.value, label: client.label}
                    })}
                  />
                </Form.Item>
              </Col>
            }
            <Col sm={8}>
              <Form.Item
                name="task"
                label='סוג משימה'
                rules={[{required: true, message: 'שדה חובה'}]}>
                <Select
                  disabled={!isNew}
                  placeholder={'בחר סוג משימה'}
                >
                  {
                    selectOptionTasks && selectOptionTasks.length > 0 && selectOptionTasks.map((task: any, index: any) => (
                      <Select.Option key={index} value={task.value}>{task.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            {selectOptionSubTasks && selectOptionSubTasks.length > 0 && <Col sm={8}>
              <Form.Item
                name="subtask"
                label='תת משימה'
                rules={!form.getFieldValue('task') || selectOptionSubTasks.length === 0 ? [] : [{
                  required: true,
                  message: 'שדה חובה'
                }]}>
                <Select
                  disabled={!isNew || !form.getFieldValue('task') || selectOptionSubTasks.length === 0}
                  placeholder={'בחר תת משימה'}
                >
                  {selectOptionSubTasks.map((subtask: any, index: any) => (
                    <Select.Option key={index} value={subtask.value}>{subtask.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>}
            <Col sm={8}>
              <Form.Item name="product" label='מוצר קיים'>
                <Select
                  disabled={!form.getFieldValue('client') && (!isNew || isSelected || !clientId)}
                  placeholder={client && client.products.length === 0 ? 'אין מוצרים לעמית זה!' : 'בחר מוצר קיים'}
                >
                  {
                    selectOptionProducts && selectOptionProducts.length > 0 && selectOptionProducts.map((product: any, index: any) => (
                      <Select.Option key={index} value={product.value}>{product.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item name="policy" label='מס׳ חשבון/פול׳ קיים'>
                <Select
                  disabled={!form.getFieldValue('product') || !isNew || isSelected}
                  placeholder={'בחר חשבון/פול׳ קיים'}
                >
                  {
                    selectOptionPolicies && selectOptionPolicies.length > 0 && selectOptionPolicies.map((policy: any, index: any) => (
                      <Select.Option key={index} value={policy.value}>{policy.label}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                name="status"
                label='סטטוס משימה'
                rules={!form.getFieldValue('task') || selectOptionSubTasks.length === 0 ? [] : [{
                  required: true,
                  message: 'שדה חובה'
                }]}>
                <Select
                  style={{width: '100%'}}
                  placeholder={'בחר סטטוס משימה'}
                >
                  {statuses.map((status: any, index: any) => (
                    <Select.Option key={index} value={status.value}>{status.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            {dynamicFields.map((field, index) => (
              <Col key={index} lg={8}>
                <DynamicField
                  manufacturers={selectOptionsManufacturers}
                  field={field}
                  isNew={isNew}
                  existingFile={field.type === 'file' ? getExistingFile(field.name) : undefined}/>
              </Col>
            ))}
          </Row>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'fontFamily'],
              blockType: {
                inDropdown: true,
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
              },
              fontSize: {
                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
              },
              fontFamily: {
                options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
              }
            }}
          />
        </Form>
      </Loader.LoadingContainer>
    </Modal>
  );
};
export default TaskFormModal;
