import React from 'react';
import {Breadcrumb, Col, Row, Typography} from "antd";

const {Text} = Typography;

interface BreadcrumbProps {
  item: string;
  href?: string;
}

interface Props {
  title?: string;
  breadcrumbs?: BreadcrumbProps[];
}

const Breadcrumbs: React.FC<Props> = (props) => {
  return (
    <Row className='m-2'>
      <Col lg={20}>
        {props.title &&
          <Text style={{
            fontSize: 17,
            fontWeight: 600,
          }}>{props.title}</Text>
        }
      </Col>
      <Col lg={4}>
        <Breadcrumb style={{float: 'left'}} separator=">">
          {
            props.breadcrumbs?.map((breadcrumb, index) =>
              <Breadcrumb.Item key={index}
                               href={breadcrumb.href ? breadcrumb.href : "#"}>{breadcrumb.item}</Breadcrumb.Item>
            )
          }
        </Breadcrumb>
      </Col>
    </Row>
  );
};

export default Breadcrumbs;
