import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, FormInstance, Select, Space, Tooltip} from "antd";
import {getClientsList, getTasks, updateClientTask} from "../../../services/client";
import {ActionType, ProColumns} from "@ant-design/pro-components";
import {getUsersByType} from "../../../services/user";
import moment from "moment";
import {EditOutlined} from '@ant-design/icons';
import Messages from "../../antDesign/Message";
import {getProductsList} from "../../../services/product";
import {getTaskProperties} from "../../../services/task";
import Table from "../../antDesign/ProTable";
import {statuses} from "../../../pages/Tasks/statuses";
import TaskFormModal from "../../Tasks/components/TaskForm/TaskFormModal";
import ExportToExcelButton from "../../ExportToExcel";
import {RiSearchLine} from "react-icons/ri";
import CopyToClipboard from "../../CopyToClipboard";

const {Option} = Select;

interface Props {
  clientId?: string;
  visibleAddEditTaskModal?: boolean;
  onClose?: () => void;
  filter?: boolean;
  reload?: boolean;
  onReloaded?: () => void;
}

const Tasks: React.FC<Props> = (props) => {
  const [addEditTaskModalShow, setAddEditTaskModalShow] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<any>({});
  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [isNew, setNew] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectTreatment, setSelectTreatment] = useState<any>([]);
  const [selectTasks, setSelectTasks] = useState<any>([]);
  const [enumTreatment, setEnumTreatment] = useState<any>(undefined);
  const [enumSubtasks, setEnumSubtasks] = useState<Record<string, any>>({});
  const [enumClients, setEnumClients] = useState<any>(undefined);
  const [enumProducts, setEnumProducts] = useState<any>(undefined);
  const [filterParams, setFilterParams] = useState<any>({});
  const actionRef = useRef<ActionType>();

  const handleStatusChange = (value: string, record: any) => {
    const status = statuses.filter(status => {
      return status.value === Number(value)
    })[0];

    const data = {
      id: record._id,
      agentId: record.agent_id,
      client: record.client_id,
      treatment: record.treatmentId,
      property: record.properties_id,
      policy: !record.policy_id ? '' : record.policy_id,
      type: record.type,
      body: record.body,
      status: JSON.stringify(status)
    }

    updateClientTask(data).then((response) => {
      if (response.status.toString() === 'SUCCESS') {
        if (actionRef.current) {
          actionRef.current.reload();
        }
        Messages.Success('מצב המשימה עודכן בהצלחה').then(() => {
        });
      } else
        Messages.Error('עדכון מצב המשימה נכשל').then(() => {
        });
    })
  }

  const handleTreatmentChange = (value: string, record: any) => {
    const data = {
      id: record._id,
      agentId: record.agent_id,
      client: record.client_id,
      property: record.properties_id,
      treatment: value,
      policy: !record.policy_id ? '' : record.policy_id,
      type: record.type,
      body: record.body,
      status: record.status
    }
    updateClientTask(data).then((response) => {
      if (response.status.toString() === 'SUCCESS')
        Messages.Success('גורם מטפל עודכן בהצלחה').then(() => {
        });
      else
        Messages.Error('עדכון גורם מטפל נכשל').then(() => {
        });
    })
  }

  const getTreatments = async () => {
    await getUsersByType({type: 'treatment'}).then(response => {
      if (response.status.toString() === 'SUCCESS') {
        if (response.users.length > 0) {
          const options: any = [];
          const enumOptions: any = {};
          response.users.forEach((treatment: any) => {
            enumOptions[treatment._id] = {
              text: treatment.firstName + ' ' + treatment.lastName,
              status: treatment._id
            }
            options.push({
              label: treatment.firstName + ' ' + treatment.lastName,
              value: treatment._id
            })
          })
          setSelectTreatment(options);
          setEnumTreatment(enumOptions);
        }
      }
    })
  }

  const columns: ProColumns[] = [
    {
      align: 'right',
      sorter: (a: any, b: any) => a.product_name ? a.product_name.localeCompare(b.product_name) : false,
      title: "מוצר",
      dataIndex: 'product_name',
      valueEnum: enumProducts,
      fieldProps: {
        placeholder: 'מוצר'
      }
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => a.type ? a.type.localeCompare(b.type) : false,
      title: "סוג משימה",
      dataIndex: 'type',
      fieldProps: {
        placeholder: 'סוג משימה'
      },
      renderFormItem: (item: any, {type, defaultRender, ...rest}: any, form: FormInstance) => {
        return (<Select placeholder={'סוג משימה'} onChange={(value) => {
          form.setFieldsValue({type: value});
          setEnumSubtasks([]);
          form.resetFields(['subtype'])
          getTaskType({type: value})
            .then((properties: any) => {
              if (properties && properties.length > 0) {
                const options: any = {}
                properties.forEach((property: any) => {
                  if (property.subtype) {
                    options[property.subtype] = {
                      _id: property._id,
                      text: property.subtype_label,
                      status: property.subtype
                    }
                  }
                })
                setEnumSubtasks(options)
              }
            });
        }}>
          {
            selectTasks && selectTasks.length > 0 &&
            selectTasks.map((option: any) =>
              <Option key={option.value} value={option.value}>{option.label}</Option>)
          }
        </Select>);
      },
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => a.type ? a.type.localeCompare(b.type) : false,
      title: "תת משימה",
      dataIndex: 'subtype',
      fieldProps: {
        placeholder: 'תת משימה'
      },
      hideInSearch: !enumSubtasks || Object.keys(enumSubtasks).length === 0,
      valueEnum: enumSubtasks
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => a.name ? a.name.localeCompare(b.name) : false,
      title: "לקוח",
      dataIndex: 'name',
      valueEnum: enumClients,
      fieldProps: {
        placeholder: 'לקוח'
      },
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => (
        <CopyToClipboard text={record.name}/>
      ),
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => a.id ? a.id.localeCompare(b.id) : false,
      title: "ת.ז",
      dataIndex: 'id',
      fieldProps: {
        placeholder: 'ת.ז'
      },
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => (
        <CopyToClipboard text={record.id}/>
      ),
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => a.policyId ? a.policyId.localeCompare(b.policyId) : false,
      title: "פוליסה",
      dataIndex: 'policyId',
      fieldProps: {
        placeholder: 'פוליסה'
      },
      render: (text: any, record: any) => (
        <CopyToClipboard text={text}/>
      )
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => moment(a.createdAt).isSameOrBefore(b.createdAt) ? 1 : -1,
      title: "תאריך יצירה",
      dataIndex: 'createdAt',
      valueType: 'date',
      fieldProps: {
        placeholder: 'תאריך יצירה'
      },
      render: (_: any, record: any) => (record.createdAt.format('DD/MM/YYYY'))
    },
    {
      align: 'right',
      sorter: (a: any, b: any) => moment(a.updatedAt).isSameOrBefore(b.updatedAt) ? 1 : -1,
      title: "תאריך עדכון",
      dataIndex: 'updatedAt',
      valueType: 'date',
      fieldProps: {
        placeholder: 'תאריך עדכון'
      },
      render: (_: any, record: any) => (record.updatedAt.format('DD/MM/YYYY'))
    },
    /*  {
        align: 'right',
        title: "גורם מטפל",
        dataIndex: 'treatmentId',
        fieldProps: {
          placeholder: 'גורם מטפל'
        },
        hideInSearch: true,
        valueEnum: enumTreatment,
        render: (_: any, record: any) => [
          <div key={record._id}>
            {
              selectTreatment && selectTreatment.length > 0 &&
              <Select defaultValue={record.treatmentId === getLoggedInUserId() ? '' : record.treatmentId}
                      style={{width: 120}}
                      onChange={(value) => handleTreatmentChange(value, record)}>
                {
                  selectTreatment.map((treatment: any) => {
                    return <Option key={treatment.value} value={treatment.value}>{treatment.label}</Option>
                  })
                }
              </Select>
            }
            {/!*{(!selectTreatment || selectTreatment.length === 0) &&
              <>-</>
            }*!/}
          </div>
        ],
      },*/
    {
      align: 'right',
      title: "סטאטוס המשימה",
      dataIndex: 'status',
      fieldProps: {
        placeholder: 'מצב המשימה'
      },
      hideInSearch: true,
      width: 220,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Select value={record.status}
                  style={{width: '100%'}}
                  onChange={(value) => handleStatusChange(value, record)} options={statuses}>
          </Select>
        </div>
      ],
    },
    {
      align: 'right',
      title: "",
      dataIndex: 'actions',
      hideInSearch: true,
      width: 50,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // Prevent the row click event
        },
      }),
      render: (_: any, record: any) => [
        <div key={record._id}>
          <Tooltip title='ערוך משימה'>
            <Button
              type='link'
              onClick={() => handleEditTask(record)}>
              <EditOutlined/>
            </Button>
          </Tooltip>
        </div>
      ]
    }
  ];

  const closeAddEditTaskModal = (refresh: boolean) => {
    // Clear modal state
    setAddEditTaskModalShow(false);

    // Handle parent component callback if exists
    if (props.onClose) {
      props.onClose();
    }

    // Refresh table if needed
    if (refresh) {
      // Make sure actionRef exists before calling reload
      if (actionRef.current) {
        actionRef.current.reload();
      }
    }
  }

  const handleAfterClose = () => {
    setNew(true);
    setClientId(undefined);
    setTaskData({})
  }

  const handleEditTask = (record: any) => {

    const policy: any = [];
    {
      record.client && record.client.products && record.client.products.length > 0 &&
      record.client.products.forEach((product: any) => {
        product.policies.forEach((p: any) => {
          if (p.policyNumberOrAccountNumber && record.policyId &&
            p.policyNumberOrAccountNumber === record.policyId) {
            policy.push(p)
          }
        })
      })
    }

    const _task = {
      _id: record._id,
      agent_id: record.agent_id,
      properties_id: record.properties_id,
      treatment_id: record.treatmentId,
      properties: record.properties,
      fields_values: record.fields_values,
      body: record.body,
      productType: {
        id: record.product_id,
        name: record.product_name,
      },
      policy_id: record.policyId,
      status: record.status,
      createdAt: record.createdAt,
      updatedAt: record.updatedAt,
      product: policy && policy.length > 0 ? policy[0] : [],
    }
    setTaskData(_task);
    setClientId(record.client && record.client._id ? record.client._id : undefined);
    setNew(false);
    setAddEditTaskModalShow(true)
  }

  const loadDataTable = async (params: any) => {
    let isExportToExcel = false;
    if (params && params.current) {
      setFilterParams(params);
    } else {
      isExportToExcel = true;
    }

    if (props.clientId)
      params.name = props.clientId;

    return await getTasks(isExportToExcel ? {
      ...filterParams,
      current: undefined,
      pageSize: undefined
    } : params).then((data) => {
      const dataTable = data.tasks.map((task: any) => {
        return {
          _id: task._id,
          type: task.properties.type_label,
          subtype: task.properties.subtype_label,
          name: task.client && task.client.firstName && task.client.lastName ?
            task.client.firstName + " " + task.client.lastName : '',
          id: task.client && task.client.clientId ? task.client.clientId : '',
          policyId: task.policy_id,
          createdAt: !isExportToExcel ? moment(task.createdAt) : moment(task.createdAt).format('DD/MM/YYYY'),
          updatedAt: !isExportToExcel ? moment(task.updatedAt) : moment(task.updatedAt).format('DD/MM/YYYY'),
          treatmentId: task.treatment_id,
          status: !isExportToExcel ? task.status : task.status.label,
          agent_id: task.agent_id,
          body: task.body,
          client: task.client,
          client_id: task.client_id,
          company_id: task.company_id,
          created_by: task.created_by,
          fields_values: task.fields_values,
          properties: task.properties,
          properties_id: task.properties_id,
          product_id: task.product_id,
          product_name: task.product_name,
        }
      })
      return {
        status: data.status,
        current: data.current,
        total: data.total,
        pageSize: data.pageSize,
        data: dataTable,
      };
    })
  }

  const getClients = () => {
    getClientsList().then(response => {
      if (response.list.length > 0) {
        const options: any = {}
        response.list.forEach((client: any) => {
          options[client._id] = {
            text: client.firstName + ' ' + client.lastName + ' - ' + client.clientId,
            status: client._id
          }
        })
        setEnumClients(options)
      }
    })
  }

  const getProducts = () => {
    getProductsList().then((response) => {
      if (response.status && response.status.toString() === 'SUCCESS') {
        if (response.products.length > 0) {
          const options: any = {};

          response.products.forEach((product: any) => {
            options[product.id] = {
              text: product.name,
              status: product.id
            }
          })
          setEnumProducts(options);
        }
      }
    })
  }

  const getTaskType = async (data: any | undefined = undefined) => {
    return await getTaskProperties(data).then(response => {
      if (response.status.toString() === 'SUCCESS')
        return response.properties;
      return [];
    })
  }

  const init = () => {
    getTreatments().then(() => {
    });
    getClients();
    getProducts();

    getTaskType().then((properties: any) => {
      if (properties.length > 0) {
        const options: any = {}
        const optionsSelect: any = [];
        properties.forEach((property: any) => {
          options[property.type] = {
            _id: property._id,
            text: property.type_label,
            status: property.type
          }
          optionsSelect.push({
            label: property.type_label,
            value: property.type,
          })
        })
        setSelectTasks(optionsSelect);
      }
    });
  }

  useEffect(() => {
    init()
    return () => {
    }
  }, []);

  useEffect(() => {
    if (props.reload) {
      actionRef.current?.reload();
      if (props.onReloaded)
        props.onReloaded()
    }
    return () => {
    }
  }, [props.reload])

  return (
    <Card title="משימות"
          bordered={false}
          extra={
            <Space>
              <Button
                type="default"
                size="middle"
                icon={<RiSearchLine/>}
                onClick={() => setShowFilter(!showFilter)}
                title="חיפוש"
              />
              <ExportToExcelButton
                loadDataTable={loadDataTable}
                fileName="משימות"
                columns={columns.filter((col) => col.dataIndex !== 'actions')}
              />
            </Space>
          }
    >
      <Table
        handleRowClick={(record) => handleEditTask(record)}
        style={{cursor: 'pointer'}}
        actionRef={actionRef}
        showFilter={showFilter}
        request={loadDataTable}
        // @ts-ignore
        columns={columns}
        showPagination
      />

      <TaskFormModal visible={addEditTaskModalShow || props.visibleAddEditTaskModal || false}
                     task={taskData}
                     clientId={clientId}
                     isNew={isNew}
                     afterClose={() => handleAfterClose()}
                     onOk={() => {
                       closeAddEditTaskModal(true)
                     }}
                     onCancel={() => closeAddEditTaskModal(false)}/>
    </Card>
  );
};

export default Tasks;
